import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule} from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';








@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatCardModule,
       /* MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule, */
        MatGridListModule, 
        MatInputModule,
        MatTableModule,
        MatListModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatToolbarModule
    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatCardModule,
       /* MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule, */
        MatGridListModule, 
        MatInputModule,
        MatTableModule,
        MatListModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatToolbarModule
    ]
})
export class MaterialModule { }
