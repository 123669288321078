<section class="dash">
    <h2>Resultados</h2>
    <h3>Indicadores transversales</h3>
    <table class="table-result">
        <thead>
        <tr class="mat-header-row">
            <th>No I.</th>
            <th>Valoración</th>
            <th>Justificación de la valoración</th>
            <th>Documentos</th>
        </tr>
        </thead>
        <tr *ngFor="let transversal of indTransver" class="tr-body">
            <td><a class="label-indicator-result" matTooltip="{{transversal.indicatorNumber}}:  {{transversal.diagnosticIndicator}}" aria-label="">{{ transversal.indicatorNumber }}</a></td>
            <td class="result-v"><span class="cal ind-{{ transversal.assessment }}">{{ transversal.assessment }}</span></td>
            <td>{{ transversal.observations }}</td>
            <td class="files-result"><a *ngFor="let file of transversal.attachedDocuments" href="{{ file.url }}">{{ file.name }}</a></td>
            <!-- <td></td> -->
        </tr>
    </table>

    <div *ngFor="let specific of indEspecific" class="topic-content">
        <h3>{{ specific.topic }}</h3>
        <table class="table-result">
            <thead>
                <tr class="mat-header-row">
                    <th>No I.</th>
                    <th>Valoración</th>
                    <th>Justificación de la valoración</th>
                    <th>Documentos</th>
                </tr>
            </thead>
                <tr *ngFor="let indicator of specific.indicators" class="tr-body">
                    <td><a class="label-indicator-result" matTooltip="{{indicator.indicatorNumber}}:  {{indicator.diagnosticIndicator}}" aria-label="">{{ indicator.indicatorNumber }}</a></td>
                    <td class="result-v"><span class="cal ind-{{ indicator.assessment }}">{{ indicator.assessment }}</span></td>
                    <td>{{ indicator.observations }}</td>
                    <td class="files-result"><a *ngFor="let file of indicator.attachedDocuments" href="{{ file.url }}">{{ file.name }}</a></td>
                </tr>
        </table>        
    </div>

    
    
</section>