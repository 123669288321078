 <div>
    <h1>{{name}}</h1>
</div>
<section class="result-dash">
    <div class="result">
        <div class="graphics">
            <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataPercent">
            </fusioncharts>
        </div>
    </div>
    <div class="result">
        <div class="graphics">
            <fusioncharts width="100%" height="400" type="Column2d" [dataSource]="dataCicle">
            </fusioncharts>
        </div>
    </div>
    <div class="result-er">
        <div class="graphics">
            <fusioncharts width="100%" height="400" type="Column2d" [dataSource]="dataSource">
            </fusioncharts>
        </div>
    </div>

    
    <!-- <button (click)="exportChart($event)" class="btn btn-primary">Export both Charts as a single PDF</button> -->
</section>