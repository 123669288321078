import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_DEV } from './../constants/config';

@Injectable({
  providedIn: 'root'
})

export class CicleStagesService {

  private HOST: string = ENV_DEV.URL;

  constructor( private httpClient: HttpClient ) { }

  getCicleStages () {
    const ENDPOINT = 'cicle-stages';
    return this.httpClient.get(`${this.HOST}${ENDPOINT}`)
  }

}
