import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/class/errorForm';
import { Router } from '@angular/router';
import { ResetPassService } from '../../services/reset-pass.service'
import { Loading } from '../../class/loading';
import { newpassModel } from '../../model/new-pass.model'

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.scss']
})
export class ResponseResetComponent implements OnInit {

  formPassResponse: FormGroup;
  matcher = new MyErrorStateMatcher();
  response = new newpassModel()
  errorMessage: string;
  successMessage: string;
  classLoading: boolean = false;
  loading = new Loading();
  IsvalidForm = true;

  constructor(private formBuilder: FormBuilder, private router: Router, private resetPassService: ResetPassService,) { }

  ngOnInit(): void {
    this.formPassResponse = this.formBuilder.group({
      name: ['', [Validators.required]],
      temp_pass: ['', [Validators.required]],
      new_pass: ['', [Validators.required]]
    })
  }

  get formL() { return this.formPassResponse.controls; }

  ResponseResetPass(form) {
    
    if (form.valid) {
      this.IsvalidForm = true;
      this.loading.viewLoading();
      this.resetPassService.setResponse(this.formPassResponse.value).subscribe(
        data => {
          this.loading.closeLoading();
          this.successMessage = "Su cambio de contraseña ha sido exitoso";
          setTimeout(() => {
            this.successMessage = null;
            this.router.navigate(['login']);
          }, 5000);
        },
        err => {
          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }

}
