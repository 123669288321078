import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/class/errorForm';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/model/user.model';
import { Store } from '@ngxs/store';
import { setLogin, getInfoUser, getGuides, getGuideDataFromDb } from '../../store/actions/app-state.action';
import { Loading } from '../../class/loading';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  formLogin: FormGroup;
  matcher = new MyErrorStateMatcher();
  user = new UserModel()
  errorMessage: string;
  yearNow: number = new Date().getFullYear();
  classLoading: boolean = false;
  loading = new Loading();
  theYear: number = new Date().getFullYear();

  constructor( private formBuilder: FormBuilder, private router: Router, private store: Store) { }

  ngOnInit(): void {
    this.formLogin = this.formBuilder.group({
      nit: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

  get formL() { return this.formLogin.controls; }

  getLogins( form ) {
    this.user.name = this.formLogin.get('nit').value
    this.user.pass = this.formLogin.get('password').value
    if ( form.valid ) {
      this.loading.viewLoading();
      this.store.dispatch(
        new setLogin(this.user))
        .subscribe( resp => {
          this.actionInfoUser();
        }, error => {
          this.loading.closeLoading();
          this.errorMessage = 'Su usuario y/o contraseña son incorrectas'
        } )
    } 
  }

  actionInfoUser(){
    this.store.dispatch(new getGuideDataFromDb(this.theYear)).subscribe( resp => {
      this.store.dispatch(
        new getInfoUser()
      )
      .subscribe( resp => {
        this.getActiveGuide();
        this.router.navigateByUrl(`/mi-perfil?year=${this.theYear}`);
        this.loading.closeLoading()
      })
    })
    
  }

  getActiveGuide() {
    this.store.dispatch(
      new getGuides( this.yearNow )
    )
    .subscribe( resp => {
    })
  }

  onChange(event) {
    this.yearNow = event.target.value;
    this.theYear = event.target.value;
  }
}
