import { Injectable } from '@angular/core';
import { ENV_DEV } from '../constants/config';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private HOST: string = ENV_DEV.URL;
  private LOCAL_HOST = ENV_DEV.LOCAL_URL
  private BASE_ENDPOINT = ENV_DEV.BASE_ENDPOINT

  constructor( private httpClient: HttpClient ) { }

  getInfo( userId ) {
    const ENDPOINT = `user/${userId}`
    return this.httpClient.get(`${this.HOST}${ENDPOINT}`)
  }

  getGuides ( userId, yearValidity  ) {
    const ENDPOINT = 'user/guides'
    return this.httpClient.post(`${this.HOST}${ENDPOINT}`, { userId, yearValidity })
  } 

  postContactUserInfo ( userId, contactName, contactMail, contactPhone ) {
    const ENDPOINT = 'user/contact'

    const postData = {
      userId : userId,
	    mail: contactMail,
	    name: contactName,
	    phone: contactPhone
    }
    
    return this.httpClient.post(`${this.HOST}${ENDPOINT}`, postData)
  }
}
