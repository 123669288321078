import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { AppState } from '../../store/states/app-state.state';
import { Observable } from 'rxjs';
import { Session } from '../../class/session';
import { Router, ActivatedRoute } from '@angular/router';
import { getGuideDataFromDb, updateCurrentGuideData } from 'src/app/store/actions/app-state.action';


@Component({
  selector: 'app-dashboard',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  
  session = new Session();

  @Select(AppState.selectGuidesData) itemData$: Observable<any>;
  @Select(AppState.selectInfoCompany) infoCompany$: Observable<any>;
  @Select(AppState.selectInfoUser) infoUser$: Observable<any>;


  guidesData= new Array;
  userInfo= new Array;
  companyInfo= new Array;
  public yearOld: number;
  
  
  constructor( 
    private router: Router, 
    private store: Store,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let year = params['year'];
      if(year != null) {
      this.yearOld = year;
      localStorage.setItem('yearActive', year);
      this.session.validateUser(this.router);
      this.store.dispatch(new getGuideDataFromDb(this.yearOld)).subscribe( () => {
        this.store.dispatch(new updateCurrentGuideData())
      })
      }
      
    });
  /*   this.getInfoCompany();
    this.getInfoUserData();
    this.listGuides(); */
    this.listGuides();
  }

  getInfoUserData() {
    this.infoUser$
    .subscribe( infoUser => {
      this.userInfo = infoUser;
    })
  }
  
  getInfoCompany() {
    this.infoCompany$
    .subscribe( infoCompany => {
      this.companyInfo = infoCompany;
    })
  }

  listGuides(){
    this.itemData$
    .subscribe( resp => {
      
      if ( resp.length <= 0 ){
        
       
      } else {
        this.guidesData = resp;
        
      }
      
    })

  }
}
