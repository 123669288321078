export class InitializeTopics {
    static readonly type = '[Topics] Initialize Topics';
    /* constructor(public topics: string) {} */
  }

export class InitializeChildTopics {
    static readonly type = '[Topics] Initialize Child Topics';
}

export class InitializeParentTopics {
  static readonly type = '[Topics] Initialize Parent Topics';
}