import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../../class/session';
import { Observable } from 'rxjs';
import { AppState } from '../../store/states/app-state.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Select(AppState.selectInfoCompany) infoCompany$: Observable<any>;

  session = new Session();
  companyInfo= new Array;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.session.validateUser(this.router);
    this.getInfoCompany();
  }

  getInfoCompany() {
    this.infoCompany$
    .subscribe( infoCompany => {
      this.companyInfo = infoCompany;
    })
  }

}
