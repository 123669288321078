
export class InitializeIndicators {
    static readonly type = '[Indicators] Initialize Indicators';
  }


export class InitializeSpecificIndicators {
    static readonly type = '[Indicators] Initialize Specific Indicators';
  }


export class InitializeTransversalIndicators {
    static readonly type = '[Indicators] Initialize Transversal Indicators';
  }