import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENV_DEV } from './../constants/config';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  
  private HOST: string = ENV_DEV.URL
  private LOCAL_HOST = ENV_DEV.LOCAL_URL
  private BASE_ENDPOINT = ENV_DEV.BASE_ENDPOINT

  constructor( private httpClient: HttpClient ) { }

  uploadFile(fileData: File) {
    
    const ENDPOINT = 'file';

    const formData = new FormData();
    formData.append('file', fileData);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return  this.httpClient.post(`${this.HOST}${ENDPOINT}`, formData,  { headers: headers })
  }
}


