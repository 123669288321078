import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../../class/session';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  session = new Session();

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.session.validateUser(this.router);
  }
}
