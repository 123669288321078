<app-header></app-header>
<section class="profile section-page">
<div class="container">
    <h1>Perfil</h1>
    <div class="button-aplicar">
        <button mat-raised-button color="secundary" [routerLink]="['/home']">Aplicar nueva guía</button>
    </div>
    <div class="company-info">
    <app-business [companyInfo]="infoCompany$ | async"></app-business>
    <app-contact [userInfo]="infoUser$ | async"></app-contact>
    
    </div>
    <app-diagnostics [guidesActive]="this.yearOld"  [guidesData]="itemData$ | async"></app-diagnostics>
</div>
</section>