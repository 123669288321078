import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from "@angular/common/http";

import { MaterialModule } from './material.module';

import { GuideCardComponent } from './components/guide-card/guide-card.component';
import { HomeComponent } from './pages/home/home.component';
import { BusinessComponent } from './components/business/business.component';
import { ContactComponent } from './components/contact/contact.component';
import { DiagnosticsComponent } from './components/diagnostics/diagnostics.component';
import { ToolComponent } from './components/tool/tool.component';
import { ProfileComponent } from './pages/profile/profile.component';

// fusioncharts
import { FusionChartsModule } from "angular-fusioncharts";
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as Widgets from 'fusioncharts/fusioncharts.widgets';
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { TopicsState } from './store/states/topics.state'; 
import { ListComponent } from './components/list/list.component';
import { ModalComponent } from './components/modal/modal.component'; 
import { ListsComponent } from './pages/lists/lists.component';
import { IndicatorsState } from './store/states/indicators.state'; 
import { AssessmentsState } from './store/states/assessments.state';
import { CicleStagesState } from './store/states/cicle-stages.state';
import { GuidelinesState } from './store/states/guidelines.state';
import { GriStandardsState } from './store/states/gri-standards.state';
import { RectorsPrinciplesState } from './store/states/rectors-principles.state';
import { AppState } from './store/states/app-state.state';
import { LoginComponent } from './pages/login/login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// pipelines 
import { FormatdatePipe } from './pipes/formatdate.pipe';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ResultComponent } from './components/result/result.component';
import { ResetPassComponent } from './components/reset-pass/reset-pass.component';
import { ResponseResetComponent } from './components/response-reset/response-reset.component';



@NgModule({
  declarations: [
    FormatdatePipe,
    AppComponent,
    GuideCardComponent,
    HomeComponent,
    BusinessComponent,
    ContactComponent,
    DiagnosticsComponent,
    ToolComponent,
    ProfileComponent,
    ListComponent,
    ModalComponent,
    ListsComponent,
    LoginComponent,
    LoginFormComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    ResultComponent,
    ResetPassComponent,
    ResponseResetComponent

  ],
  
  imports: [
    MaterialFileInputModule,
    NgxFileDropModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    NgxsModule.forRoot([
       TopicsState,
       IndicatorsState,
       AssessmentsState,
       CicleStagesState,
       GuidelinesState,
       GriStandardsState,
       RectorsPrinciplesState,
       AppState
      ]),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    FusionChartsModule.forRoot(FusionCharts, charts, Widgets, FusionTheme),
    FusionChartsModule,
    ReactiveFormsModule, 
    FormsModule,
    CarouselModule
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
