import { Select } from '@ngxs/store';
import { AppState } from '../store/states/app-state.state';
import { Observable } from 'rxjs';
export class Session {

    @Select(AppState.selectDataLogin) DataLogin$: Observable<any>;

    dataUrl: any;

    private isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    private response() {
        this.DataLogin$.subscribe( resp => {
            this.dataUrl = resp
        });
    }

    validateUser( router ) {
        this.response();
        if (this.isEmpty(this.dataUrl)){
            router.navigateByUrl('/');
        }
    }
    validateUrl( router ) {
        this.response();
        if (!this.isEmpty(this.dataUrl)){
            router.navigateByUrl('/mi-perfil');
        }
    }
}