export const ENV_DEV = {
    /* URL: 'http://dev-fip-guias-colombia-app.pantheonsite.io/fip/v1/',
    LOGIN_URL: 'http://dev-fip-guias-colombia-app.pantheonsite.io/',
    LOCAL_URL: 'http://fip-guias-colombia-app.ddev.site:8092/',
    BASE_URL: 'http://dev-fip-guias-colombia-app.pantheonsite.io',
    BASE_ENDPOINT: 'fip/v1/' */
    URL: 'https://aplicativoguias.ideaspaz.org:8080/fip/v1/',
    LOGIN_URL: 'https://aplicativoguias.ideaspaz.org:8080/',
    LOCAL_URL: 'https://aplicativoguias.ideaspaz.org:8080/',
    BASE_URL: 'https://aplicativoguias.ideaspaz.org:8080/',
    BASE_ENDPOINT: 'fip/v1/'
    // URL: 'https://fip-guias.ddev.site/fip/v1/',
    // LOGIN_URL: 'https://fip-guias.ddev.site/',
    // LOCAL_URL: 'https://fip-guias.ddev.site/',
    // BASE_URL: 'https://fip-guias.ddev.site',
    // BASE_ENDPOINT: 'fip/v1/'
}   

