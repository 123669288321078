import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { setCurrentGuide } from '../../store/actions/app-state.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss']
})
export class GuideCardComponent implements OnInit {

  @Input('id') id: number;
  @Input('name') name: string;
  @Input('imageUrl') img: string;
  @Input('guidesData') guidesData: Array<any>;
  @Input('isActive') isActive: boolean;

  classStatus: string;
  textStatus: string;

  listIds = new Array(10);

  constructor(private store: Store, private router:Router) { 
  }

  ngOnInit(): void {
    this.listIds = [];
    this.changeStatus();
    this.arrayIds();

  }

  clickGuide(){
    this.store.dispatch(new setCurrentGuide(this.id));
    this.router.navigateByUrl('/list');
  }

  changeStatus(){
    
    for (const item of this.guidesData) {
      const statusData = item['guideState'];
      
      const idData = item['guideId'];
      if ( this.id === idData ) {
        switch (statusData) {
          case 'inProgress':
            this.classStatus = 'progress'
            this.textStatus = 'En progreso'
            break;
          case 'complete':
            this.classStatus = 'completed'
            this.textStatus = 'Completado'
           
            break;
          default:
            this.classStatus = ''
            break;
        }
      }
    }
  }

  arrayIds() {
    
  }
}
