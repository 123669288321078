import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { clearAll, storeCurrentGuide } from '../../store/actions/app-state.action';
import { ModalDialog } from '../../class/modalDialog';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  hidden: boolean = true;
  hidden2: boolean = true;
  public yerActive: number;

  modalDialog = new ModalDialog();
  
  constructor ( private store: Store, private router: Router) {
    router.events.subscribe((val) => {
      this.hidden = location.pathname === '/' ? false : true;
      this.hidden2 = location.pathname !== 'login' ? false : true;
  });
  }

  ngOnInit(): void {
    this.yerActive = parseInt(localStorage.getItem('yearActive'));
  }

  logout() {
    Swal.fire({
      text: 'Recuerda gurardar los cambios de tu guía antes de salir',
      icon: 'info',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Regresar',
      cancelButtonText: 'Salir'
    }).then((result) => {
      
      if (result.value) {
        this.router.navigateByUrl('/home');
      } else if (result.dismiss.toString() == 'cancel') {
        this.cleanAndExit()
      }
    })
  }

  cleanAndExit () {
    this.store.dispatch(new clearAll)
    this.router.navigateByUrl('/');
  }
}