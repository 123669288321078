import { UserModel } from '../../model/user.model';

export class setCurrentGuide {
    static readonly type = '[App State] Set Current Guide';
      constructor(public guideId: number) {}
}


export class storeCurrentGuide {
  static readonly type = '[App State] Store Current Guide';
   constructor(public guideIdSend: string) {}
}

export class getGuideDataFromDb {
  static readonly type = '[App State] Get Guide Data From Db';
   constructor(public yearValidity: number) {}
}



export class setIndicatorValorations {
  static readonly type = '[App State] Set Indicator Valorations';
    constructor(
                  public indicatorType: string,
                  public inidicatorId,
                  public assessment: number,
                  public observations: string,
                  public attachedDocuments: Array<any>
                ) {}
}

export class setLogin {
  static readonly type = '[App State] Set Login';
    constructor( public user: UserModel ) {}
}

export class getInfoUser {
  static readonly type = '[App State] Set infouser';
    constructor( ) {}
}

export class setUserContactInfo {
  static readonly type = '[App State] Set Set User Contact Info';
    constructor( public contactName: string,
                 public contactMail: string,
                 public contactPhone: string) {}
}

export class getGuides {
  static readonly type = '[App State] Set Guides';
    constructor( public yearNow: number ) {}
}

export class clearAll {
  static readonly type = '[App State] Set clearAll';
    constructor( ) {}
}

export class setCurrentDashboard {
  static readonly type = '[App State] Set Current Dashboard';
    constructor(public guideId: number, public yearValidity: number) {}

  }
export class updateCurrentGuideData {
  static readonly type = '[App State] Update Current GuideData';
    constructor() {}
}