import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ListsComponent } from './pages/lists/lists.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ResetPassComponent } from './components/reset-pass/reset-pass.component'
import { ResponseResetComponent } from './components/response-reset/response-reset.component'


const routes: Routes = [
  { path: '', component: LoginComponent,
  	children: [
      {
      	path: 'login', 
      	component: LoginFormComponent 
      },
      {
      	path: 'reset-pass', 
      	component: ResetPassComponent 
      }, 
      { path: 'reset-pass-response', component: ResponseResetComponent }
    ]
  },
  { path: 'home', component: HomeComponent},
  { path: 'list', component: ListsComponent },
  { path: 'mi-perfil', component: ProfileComponent},
  { path: 'dashboard', component: DashboardComponent },

  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
