import { State, StateContext, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
   InitializeCicleStages
} from './../actions/cicle-stages.action';
import { CicleStagesService } from './../../services/cicle-stages.service';
import { Injectable } from '@angular/core';

export interface CicleStagesStateModel {
    cicleStagesData: Array<any>
}

@State<CicleStagesStateModel>({
    name: 'cicleStages',
    defaults: {
        cicleStagesData: [],
    }
})

@Injectable()
export class CicleStagesState {
    constructor(private cicleStagesService: CicleStagesService) { }

  /*   @Selector()
    static selectTopics(state: AssessmentsStateModel) {
        return state.topicsData
    } */

    @Action(InitializeCicleStages)
    initializeCicleStages(ctx: StateContext<CicleStagesStateModel>, action: InitializeCicleStages) {
        return this.cicleStagesService.getCicleStages().pipe(
            tap(cicleStagesResult => {
                const cicleStagesArray = Object.values(cicleStagesResult)
               
                const mappedData = cicleStagesArray.map(cicleStage => {
                    return {
                        id: cicleStage.tid[0].value,
                        name: cicleStage.name[0].value,
                    }
                })

                ctx.patchState({
                    cicleStagesData: mappedData
                });
            })
        );
    }
}
