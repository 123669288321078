import Swal from 'sweetalert2'

export class ModalDialog { 
    openDialog( title, text, icon){
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: 'Aceptar'
          })
    }
}