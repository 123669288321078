<section class="login">
    <form [formGroup]="formLogin" (ngSubmit)="getLogins( form )" #form="ngForm">
        <a class="cerrar" [routerLink]="['']">X</a>
        <img src="assets/img/logo-color.png" width="150"/>
        <h3>Iniciar sesión</h3>
        <div id="errorMsg" class="error-msg" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
        </div>
        <mat-form-field>
            <mat-label>Usuario</mat-label>
            <input matInput [errorStateMatcher]="matcher" formControlName="nit" [ngClass]="{ 'is-invalid': formL.nit.errors }">
            <mat-error *ngIf="!!formL['nit'].errors">
                <mat-error *ngIf="!!formL['nit'].errors.required">Este campo es requerido</mat-error>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Contraseña</mat-label>
            <input type="password" matInput [errorStateMatcher]="matcher" formControlName="password" [ngClass]="{ 'is-invalid': formL.password.errors }">
            <mat-error *ngIf="!!formL['password'].errors">
                <mat-error *ngIf="!!formL['password'].errors.required">Este campo es requerido</mat-error>
            </mat-error>
        </mat-form-field>
        <select class="select" (change)="onChange($event)">
            <option value="{{yearNow}}">Año de aplicación</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
        </select>
        <a class="reset-pass" [routerLink]="['../reset-pass']">Olvidé mi Contraseña</a>
        
        <div class="button">
            <button mat-raised-button color="primary">Aceptar</button>
        </div>
    </form>
</section>