import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_DEV } from './../constants/config';

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  private HOST: string = ENV_DEV.URL
  private LOCAL_HOST = ENV_DEV.LOCAL_URL
  private BASE_ENDPOINT = ENV_DEV.BASE_ENDPOINT

  constructor(private httpClient: HttpClient) { }

  postGuideChanges(newGuideData: object) {
    
    const ENDPOINT = 'guide'
    this.httpClient.post(`${this.HOST}${ENDPOINT}`, newGuideData)
      .subscribe(postResponse => {
        
      }, err => {
        
      })
  }

  getGuideUpdatedData (userId, yearValidity) {
    const ENDPOINT = `guide/${userId}/${yearValidity}`
    return this.httpClient.get(`${this.HOST}${ENDPOINT}`)
  }

  getSingleGuide (userId, yearValidity, guideId) {
    const ENDPOINT = `guide/${userId}/${yearValidity}/${guideId}`
    return this.httpClient.get(`${this.HOST}${ENDPOINT}`)
  }
}


