import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { getInfoUser } from '../../store/actions/app-state.action';
import { Session } from '../../class/session';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

  @Input('companyInfo') companyInfo: Array<any>

  image: string;
  name: string;
  nit: string;

  constructor( private store: Store ) { }

  ngOnInit(): void {
    this.getInfoCompany();
  }

  getInfoCompany(){
    this.image = this.companyInfo['companyImage'];
    this.name = this.companyInfo['companyName'];
    this.nit = this.companyInfo['companyNit'];
  }

}
