<section class="login">
    
    <form [formGroup]="formPassResponse" (ngSubmit)=" ResponseResetPass(formPassResponse)">
        <a class="cerrar" [routerLink]="['']">X</a>
        <img src="assets/img/logo-color.png" width="150"/>
        <h3>Restablecer contraseña</h3>
        <div id="errorMsg" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
        </div>
        <div id="successMsg" class="success-msg" *ngIf="successMessage">
            <span>{{successMessage}}</span>
        </div>
        <mat-form-field>
            <mat-label>Usuario</mat-label>
            <input matInput [errorStateMatcher]="matcher" formControlName="name">
            <mat-error *ngIf="!!formL['name'].errors">
                <mat-error *ngIf="!!formL['name'].errors.required">Este campo es requerido</mat-error>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Contraseña temporal</mat-label>
            <input type="password" matInput [errorStateMatcher]="matcher" formControlName="temp_pass">
            <mat-error *ngIf="!!formL['temp_pass'].errors">
                <mat-error *ngIf="!!formL['temp_pass'].errors.required">Este campo es requerido</mat-error>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Nueva contraseña</mat-label>
            <input type="password" matInput [errorStateMatcher]="matcher"  formControlName="new_pass">
            <mat-error *ngIf="!!formL['new_pass'].errors">
                <mat-error *ngIf="!!formL['new_pass'].errors.required">Este campo es requerido</mat-error>
            </mat-error>
        </mat-form-field>
        
        <div class="button">
            <button mat-raised-button color="primary">Aceptar</button>
        </div>
    </form>
</section>