export const htmlTextRemove = (textWithHtml) => {
    return textWithHtml ? textWithHtml.replace(/(<([^>]+)>)/ig,"") : ''
}
 
export const compareValues =  (key) => {
    return (a, b) => { 
        const aNumber = a[key]
        const bNumber = b[key]
       
    
        let comparison = 0;
        if (aNumber > bNumber) {
            comparison = 1;
        } else if (aNumber < bNumber) {
            comparison = -1;
        }
        return comparison;
    }
   
}