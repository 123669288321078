
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
   InitializeRectorsPrinciples
} from './../actions/rectors-principles.action';
import { RectorsPrinciplesService } from './../../services/rectors-principles.service';
import { Injectable } from '@angular/core';

import { htmlTextRemove } from './../../helpers/stringsHelper';

export interface RectorsPrinciplesModel {
    rectorsPrinciplesData: Array<any>
}

@State<RectorsPrinciplesModel>({
    name: 'rectorsPrinciples',
    defaults: {
        rectorsPrinciplesData: [],
    }
})

@Injectable()
export class RectorsPrinciplesState {
    constructor(private rectorsPrinciplesService: RectorsPrinciplesService) { }

  /*   @Selector()
    static selectTopics(state: RectorsPrinciplesModel) {
        return state.topicsData
    } */

    @Action(InitializeRectorsPrinciples)
    initializeRectorsPrinciples(ctx: StateContext<RectorsPrinciplesModel>, action: InitializeRectorsPrinciples) {
        return this.rectorsPrinciplesService.getRectorsPrinciples().pipe(
            tap(rectorsPrinciplesResult => {
                const rectorsPrinciplesArray = Object.values(rectorsPrinciplesResult)
                 
                const mappedData = rectorsPrinciplesArray.map(rectorPrinciple => {
                    return {
                        id: rectorPrinciple.tid[0].value,
                        name: rectorPrinciple.name[0].value,
                        description: htmlTextRemove(rectorPrinciple.description[0].value)
                    }
                })
                
                ctx.patchState({
                    rectorsPrinciplesData: mappedData
                });
            })
        );
    }
}
