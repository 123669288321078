import { State, StateContext, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
   InitializeGuidelines
} from './../actions/guidelines.action';
import { GuidelinesService } from './../../services/guidelines.service';
import { Injectable } from '@angular/core';

import { htmlTextRemove } from './../../helpers/stringsHelper';


export interface GuidelinesStateModel {
    guidelinesData: Array<any>
}

@State<GuidelinesStateModel>({
    name: 'guidelines',
    defaults: {
        guidelinesData: [],
    }
})

@Injectable()
export class GuidelinesState {
    constructor(private guidelinesService: GuidelinesService) { }

  /*   @Selector()
    static selectTopics(state: GuidelinesStateModel) {
        return state.topicsData
    } */

    @Action(InitializeGuidelines)
    InitializeGuidelines(ctx: StateContext<GuidelinesStateModel>, action: InitializeGuidelines) {
        return this.guidelinesService.getGuidelines().pipe(
            tap(guidelinesResult => {

                const guidelinesArray = Object.values(guidelinesResult)
                const mappedData = guidelinesArray.map(guideLine => {
                    return {
                        id: guideLine.tid[0].value,
                        name: guideLine.name[0].value,
                        description: htmlTextRemove(guideLine.description[0].value)
                    }
                })

                ctx.patchState({
                    guidelinesData: mappedData
                });
            })
        );
    }
}
