import { Component, OnInit, Input } from '@angular/core';
import { Diagnostics } from '../../interfaces/diagnostics.interface';
import { Store } from '@ngxs/store';


import { setCurrentGuide, setCurrentDashboard, updateCurrentGuideData } from '../../store/actions/app-state.action';

import { Router } from '@angular/router';

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})

export class DiagnosticsComponent implements OnInit {

  
  @Input('guidesData') guidesData: Array<any>;
  @Input('guidesActive') guidesActive: string;

  statusFile: boolean = false;
  statusButton: string;
  totalSpecific: number; 
  totalTransversal: number;
  indicators: Array<object>;

  idGuide: number;

  infoItem: Diagnostics[] = []

  constructor( private store: Store, private router:Router ) { }

  ngOnInit(): void {
    this.newArray()
    console.log('infoItem', this.infoItem);
    console.log('guidesActive', this.guidesActive);
  }

  clickView( element ) {
    console.log('Guia', element);
    localStorage.setItem('gideReportYear', element.date);
    localStorage.setItem('guideReportId', element.id);
    this.store.dispatch(new setCurrentGuide(element.id));
    this.store.dispatch( 
      new setCurrentDashboard( element.id, element.yearValidity )
    )
    .subscribe( resp => {
      if( element.status === 'En Progreso'){
        this.router.navigateByUrl('/home');
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    })
  }

  newArray() {
    
    this.guidesData.forEach(element => {
        if (element['guideId']) {
          this.indicators = element['indicators'];

          const id = element['guideId'];
          const title = element['guideName'];
          const date = element['guideDate'];
          const points = element['guideScores']['totalScore']
          let status = element['guideState'];
          const yearValidity = element['guideDate'].split('-')[0]
  
          if ( status === 'inProgress'){
              status = 'En Progreso'
              this.statusButton = 'inProgress'
          } else if( status === 'complete' ){
            status = 'Completado'
            this.statusButton = 'complete'
          }
  
        const statusFile = element['recomendationDocument'] !== '' && element['recomendationDocument'] !=='#' ? true : false
        const fileUrl = element['recomendationDocument']
          this.infoItem.push(
            {
              id: id,
              title: title, 
              date: date, 
              points: points, 
              status: status,
              statusFile,
              fileUrl,
              yearValidity
            }
          )
        }
       
    });
  }

  downloadFile (fileUrl) {
    window.open(fileUrl, '_blank')
  }

  displayedColumns: string[] = ['title', 'date', 'points', 'status', 'resul', 'recom'];
  dataSource = this.infoItem;


}
