import { State, StateContext, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
   InitializeAssessments
} from './../actions/assessments.action';
import { AssessmentsService } from './../../services/assessments.service';
import { Injectable } from '@angular/core';

import { htmlTextRemove } from './../../helpers/stringsHelper';
import { compareValues } from './../../helpers/arrayHelper';

export interface AssessmentsStateModel {
    assessmentsData: Array<any>
}

@State<AssessmentsStateModel>({
    name: 'assessments',
    defaults: {
        assessmentsData: [],
    }
})

@Injectable()
export class AssessmentsState {
    constructor(private assessmentsService: AssessmentsService) { }

  @Selector()
    static selectAssessments(state: AssessmentsStateModel) {
        return state.assessmentsData;
    }

    @Action(InitializeAssessments)
    InitializeAssessments(ctx: StateContext<AssessmentsStateModel>, action: InitializeAssessments) {
        return this.assessmentsService.getAssessments().pipe(
            tap(assessmentsResult => {
                const assessmentsArray = Object.values(assessmentsResult)
            
                const mappedData = assessmentsArray.map(assessment => {
                    return {
                        id: assessment.tid[0].value,
                        name: assessment.name[0].value,
                        description: htmlTextRemove(assessment.description[0].value)
                    }
                })

                ctx.patchState({
                    assessmentsData: mappedData.sort(compareValues('name'))
                });
            })
        );
    }
}
