import { State, StateContext, Action, Selector, Store} from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
    InitializeIndicators,
    InitializeSpecificIndicators,
    InitializeTransversalIndicators
} from './../actions/indicators.action';
import { IndicatorsService } from './../../services/indicators.service';
import { Injectable } from '@angular/core';

import { htmlTextRemove, removeAccents } from './../../helpers/stringsHelper'


export interface IndicatorsStateModel {
    indicatorsData: Array<any>,
    transversalIndicators: Array<any>
}

@State<IndicatorsStateModel>({
    name: 'indicators',
    defaults: {
        indicatorsData: [],
        transversalIndicators: []
    }
})


@Injectable()
export class IndicatorsState {
    constructor(
            private indicatorsService: IndicatorsService,
            private store: Store) { }

    @Action(InitializeIndicators)
    initializeIndicators(ctx: StateContext<IndicatorsStateModel>, action: InitializeIndicators) {
        return this.indicatorsService.getIndicators().pipe(
            tap(indicatorsResult => {

                const indicatorsArray = Object.values(indicatorsResult)
                const mappedData = indicatorsArray.map( indicator => {

                    const title = indicator.title[0].value
                    const diagnosticIndicator = indicator['field_diagnostic_indicator'].length > 0 ? 
                                                  indicator['field_diagnostic_indicator'][0].value : 
                                                  undefined

                    const justification = indicator['field_ind_justification'].length > 0   ? 
                                                     indicator['field_ind_justification'][0].value :
                                                     undefined
                    const cicleStage = indicator['field_ind_cicle_stage'].length > 0 ? 
                                         indicator['field_ind_cicle_stage'][0]['target_id'] :
                                         undefined

                   /*  const assessment = indicator['field_ind_assessment'].length > 0 ? 
                                         indicator['field_ind_assessment'][0]['target_id'] :
                                         undefined */
                    
                    const topic = indicator['field_topic'].length > 0 ? 
                    indicator['field_topic'][0]['target_id'] :
                    undefined
                    
                    const indicatorNumber = indicator['field_ind_id'][0].value
                    
                    const type = indicator['field_ind_type'][0].value


                    const rectorsPrinciples = indicator['field_principios_rectores'].map( principle => {
                        return {
                            id: principle['target_id']
                        }
                    })

                    const guideLines = indicator['field_lin_guias_colombia'].map( guideLine => {
                        return {
                            id: guideLine['target_id']
                        }
                    })

                    const griStandards = indicator['field_estandares_gri'].map( standard => {
                        return {
                            id: standard['target_id']
                        }
                    })

                   /*  const attachedDocuments = indicator['field_ind_attach_documents'].map( document => {
                        return {
                            id: document['target_id'],
                            url: document.url
                        }
                    }) */

                    const observations = null
                    const assessment = null
                    const attachedDocuments = []
                    
                    return {
                        title,
                        diagnosticIndicator,
                         attachedDocuments,
                       /*  assessment, */
                        cicleStage,
                        indicatorNumber,
                        justification: htmlTextRemove(justification),
                        type: removeAccents(type),
                        rectorsPrinciples,
                        guideLines,
                        griStandards,
                        topic,
                        observations,
                        assessment,
                        isCompleted: false,
                        isValued: false
                    }
                })

                ctx.patchState({
                    indicatorsData: mappedData.sort(this.compareValues)
                });

                ctx.dispatch(new InitializeTransversalIndicators)
                
            })
        );
    } 

    @Action(InitializeTransversalIndicators)
    initializeTransversalIndicators(ctx: StateContext<IndicatorsStateModel>, action: InitializeTransversalIndicators) {
      
        const state = ctx.getState()

        const _indicators = [...state.indicatorsData]
        let transversalIndicators = []
        for (let indicator of _indicators) {
            if (indicator.type === 'Transversal') {
                transversalIndicators = [...transversalIndicators, indicator]
            }
        }

        const storeSnapshot = this.store.selectSnapshot(state => state)

        const { griStandards, rectorsPrinciples } = storeSnapshot

        const _griStandards = [...griStandards.griStandardsData]
        const _rectorsPrinciples = rectorsPrinciples.rectorsPrinciplesData

        for (let a = 0; a < transversalIndicators.length; a++) {
            if (transversalIndicators[a].griStandards.length > 0) {
                let newGriStandards = []
                for (let j = 0; j < transversalIndicators[a].griStandards.length; j++) {

                    const matchGuideline = _griStandards.filter(storeGuideline => storeGuideline.id == transversalIndicators[a].griStandards[j].id)
                    newGriStandards = [...newGriStandards,
                    matchGuideline.length > 0 ? matchGuideline[0] : []
                    ]
                }
                if (newGriStandards.length > 0) {
                    transversalIndicators[a].griStandards = newGriStandards
                }
            }
        }

        for (let a = 0; a < transversalIndicators.length; a++) {
            if (transversalIndicators[a].rectorsPrinciples.length > 0) {
                let newRectorsPrinciples = []
                for (let j = 0; j < transversalIndicators[a].rectorsPrinciples.length; j++) {

                    const matchGuideline = _rectorsPrinciples.filter(storeGuideline => storeGuideline.id == transversalIndicators[a].rectorsPrinciples[j].id)
                    newRectorsPrinciples = [...newRectorsPrinciples,
                    matchGuideline.length > 0 ? matchGuideline[0] : []
                    ]
                }
                if (newRectorsPrinciples.length > 0) {
                    transversalIndicators[a].rectorsPrinciples = newRectorsPrinciples
                }
            }
        }
                ctx.patchState({
                    transversalIndicators: transversalIndicators.sort(this.compareValues)
                });
    } 

     compareValues (a, b) {
        const aNumber = a.indicatorNumber
        const bNumber = b.indicatorNumber
       

        let comparison = 0;
        if (aNumber > bNumber) {
            comparison = 1;
        } else if (aNumber < bNumber) {
            comparison = -1;
        }
        return comparison;
    }

}
