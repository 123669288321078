<section class="content-info-business">
    <div class="load-image">
        <div class="img">
            <img src="{{image}}" alt="" srcset="">
        </div>
        <!-- <div class="button">
            <input type="file" name="" id="">
        </div> -->
    </div>
    <div class="cont-form">
        <form action="">
            <mat-form-field class="example-full-width">
                <mat-label>Nombre Empresa</mat-label>
                <input matInput value="{{name}}" disabled>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>NIT</mat-label>
                <input matInput value="{{nit}}" disabled>
            </mat-form-field>
        </form>
    </div>
</section>