<section class="list">
    <div class="summary">
        <div class="info">
            <div class="text">
                <span>Nombre de la Guía: </span>
                <p>{{nameGuide}}</p>
            </div>
            <div class="date">
                <span>Fecha de actualización:</span>
                <p>{{(dateitem | date ) | formatdate | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="page">
                <span>Total:</span>
                <p>{{totalQues}} Indicadores</p>
            </div>
        </div>
        <div class="cont-check">
            <span>{{totalCompletedItem}}/{{totalQues}}</span>
        </div>
    </div>

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Transversales
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="item-list">

                <table mat-table [dataSource]="transversal" class="mat-elevation-z8">
                    <ng-container matColumnDef="check">
                        <th mat-header-cell *matHeaderCellDef> Estado </th>
                        <td mat-cell *matCellDef="let transversal">
                            <mat-checkbox [checked]="transversal.isCompleted"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef> Indicador </th>
                        <td mat-cell *matCellDef="let transversal"> {{transversal.diagnosticIndicator}}</td>
                    </ng-container>
                    <ng-container matColumnDef="ratings">
                        <th mat-header-cell *matHeaderCellDef>Valoración</th>
                        <td mat-cell *matCellDef="let transversal">
                            <button mat-fab disabled [ngClass]="{'blue': transversal.assessment == 0, 'red': transversal.assessment == 1, 'yellow': transversal.assessment == 2, 'green': transversal.assessment == 3}">{{transversal.assessment}}</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cycleStage">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let transversal"></td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> Modificar </th>
                        <td mat-cell *matCellDef="let transversal">
                            <button mat-stroked-button color="primary" (click)="openDialog(transversal)"><mat-icon>edit</mat-icon></button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <!-- <mat-list role="list" *ngFor="let trans of transversal">
                    <mat-checkbox (click)="openDialog(trans)">{{trans.diagnosticIndicator}}</mat-checkbox>
                </mat-list> -->
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngFor="let spec of specific">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{spec.topic}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="item-list">

                <table mat-table [dataSource]="spec.indicators" class="mat-elevation-z8">
                    <ng-container matColumnDef="check">
                        <th mat-header-cell *matHeaderCellDef> Estado </th>
                        <td mat-cell *matCellDef="let specific">
                            <mat-checkbox [checked]="specific.isCompleted"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef> Indicador </th>
                        <td mat-cell *matCellDef="let specific"> {{specific.diagnosticIndicator}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ratings">
                        <th mat-header-cell *matHeaderCellDef>Valoración</th>
                        <td mat-cell *matCellDef="let specific">
                            <button mat-fab disabled [ngClass]="{'blue': specific.assessment == 0, 'red': specific.assessment == 1, 'yellow': specific.assessment == 2, 'green': specific.assessment == 3}">{{specific.assessment}}</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cycleStage">
                        <th mat-header-cell *matHeaderCellDef> Etapa de ciclo </th>
                        <td mat-cell *matCellDef="let specificCicle"> {{specificCicle.cicleStage.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> Modificar </th>
                        <td mat-cell *matCellDef="let specificButton">
                            <button mat-stroked-button color="primary" (click)="openDialog(specificButton)"><mat-icon>edit</mat-icon></button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <!-- <mat-list role="list" *ngFor="let item of spec.indicators">
                        <mat-checkbox (click)="openDialog(item)">{{item.diagnosticIndicator}}</mat-checkbox>
                    </mat-list> -->
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="button">
        <button mat-raised-button color="primary" (click)="clickCancel()">Atrás</button>
        <button mat-raised-button color="primary" (click)="clickSave()">Guardar Respuesta</button>
        <button [disabled]="!indicatorsAreCompleted"  mat-raised-button color="primary" (click)="clickSend()">Enviar a la Secretaría Técnica</button>
    </div>
</section>

<!-- [disabled]="!indicatorsAreCompleted" -->