import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_DEV } from './../constants/config';
import { Observable } from 'rxjs';
import { newpassModel } from '../model/new-pass.model'

@Injectable({
  providedIn: 'root'
})
export class ResetPassService {

  private HOST: string = ENV_DEV.BASE_URL

  constructor(private httpClient: HttpClient) {}

  setReset(mail): Observable<any>{
    
    const ENDPOINT = '/user/lost-password?_format=json'
    const body = {mail};
    return this.httpClient.post(`${this.HOST}${ENDPOINT}`, body)
  }

  setResponse(response: newpassModel): Observable<any>{
    
    const ENDPOINT = '/user/lost-password-reset?_format=json'
    return this.httpClient.post(`${this.HOST}${ENDPOINT}`, response)
  }

}
