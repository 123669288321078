import { Component, OnInit, Input } from '@angular/core';
import { AppState } from '../../store/states/app-state.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss']
})
export class ToolComponent implements OnInit {

  @Select(AppState.selectDashboard) itemStores$: Observable<any>;

  dataSource: Object;
  dataCicle: Object;
  dataPercent: Object;

  chartData = new Array;
  chartCicle = new Array;
  chartPercent = new Array;
  
  name: string;

  width = '100%';
  height = 400;
  type = "angulargauge";
  dataFormat = "json";

  constructor() {

    const dataSource = {
      chart: {
        caption: "Resultado de avance por temas de la Guía", 
        xAxisName: "Temas",
        yAxisName: "Porcentaje",
        "showvalues": "1",
        "theme": "fusion"
      },
      data: this.chartData
    };

    const dataCicle = {
      chart: {
        caption: "Resultado según fase del ciclo PHVA", 
        xAxisName: "Etapas de Ciclo",
        yAxisName: "Porcentaje",
        "showvalues": "1",
        "theme": "fusion"
      }, 
      data: this.chartCicle
    };

    const dataPercent = {
      chart: {
        caption: "Avance en la implementación de la guía",
        "lowerLimit": "0",
        "upperLimit": "100",
        "showValue": "1",
        "numberSuffix": "%",
        "theme": "fusion",
        "showToolTip": "0"
      },
      colorrange: {
       color: [
      {
        minvalue: "0",
        maxvalue: "50",
        code: "#F2726F"
      },
      {
        minvalue: "50",
        maxvalue: "100",
        code: "#FFC533"
      }
    ]
    },
    dials: {
          dial: this.chartPercent
      }
    }

    this.dataCicle = dataCicle;
    this.dataSource = dataSource;
    this.dataPercent = dataPercent;
   }


  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
      this.itemStores$
      .subscribe( resp => {
        console.log('RESPTool', resp);
        const valueTrans = resp['transversalScore'];
        const topicsSpecific = resp['childTopicsScore'];
        const cicleStages = resp['cicleStagesScore']
        const percentTransversal = resp['transversalsPercentage'];
        const percentSpecific = resp['specificPercentage'];
      
        this.chartData.push(
          {
            label: 'Transversales',
            value: valueTrans,
            color: "#e43a35"
          }
        )

        topicsSpecific.forEach(item => {
          this.chartData.push(
            {
              label: item['topic'],
              value: item['score'],
              color: "#4f8999"
            })
        });

        cicleStages.forEach(cicle => {
            this.chartCicle.push(
              {
                label: cicle['name'],
                value: cicle['implementacionPercentage'],
                color: "#4f8999"
              })
        });

        this.chartPercent.push( 
          {
            value: percentSpecific
          }
        )
      });
  }

  graphPie(){
    
  }

}
