import Swal from 'sweetalert2';

export class Loading {
    viewLoading() {
        Swal.fire({
            // tslint:disable-next-line: max-line-length
            title: '',
            allowOutsideClick: false,
            background: 'transparent',
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
        });
        Swal.showLoading()
    }
    closeLoading() {
        Swal.close();
    }
}
