import { State, StateContext, Action, Selector, Store } from '@ngxs/store';

import {
    setCurrentGuide,
    storeCurrentGuide,
    setIndicatorValorations,
    getGuideDataFromDb,
    setLogin,
    setCurrentDashboard,
    setUserContactInfo,
    updateCurrentGuideData

} from './../actions/app-state.action';
import { Injectable } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { tap } from 'rxjs/operators';
import { GuideService } from './../../services/guide.service'
import { getInfoUser, getGuides, clearAll } from '../actions/app-state.action';
import { UserInfoService } from '../../services/user-info.service';
import { error } from '@angular/compiler/src/util';
import * as moment from 'moment';
import { ResetPassService } from '../../services/reset-pass.service';
import { state } from '@angular/animations';


export interface AppStateModel {
    currentGuideData: {
        guideId: number,
        guideName: string,
        guideState: string,
        totalIndicators: number,
        guideScores: object,
        completedIndicators: number, 
        guideDate: string,
        recomendationDocument: string,
        indicators: {
            specific: Array<any>,
            transversal: Array<any>
        }
    },
    guidesData: any,
    listActiveGuides: Array<any>,
    companyData: {
        companyName: string,
        companyNit: string,
        companyImage: string
    },
    userData: {
        contactName: string,
        contactMail: string,
        contactPhone: string
    }
    userLogin:{},
    dataDashboard: {},
}

// const yearActive = localStorage.getItem('yearActive');

// // Obtener la fecha actual
// const today = new Date();

// // Si `yearActive` es válido, reemplazar el año en la fecha actual
// if (yearActive) {
//   today.setFullYear(parseInt(yearActive));
// }

// // Crear la fecha modificada
// const createGuideData = today.toISOString();

// console.log(createGuideData);

@State<AppStateModel>({
    name: 'appState',
    defaults: {
        currentGuideData: {
            guideId: null,
            guideName: '',
            guideState: '',
            totalIndicators: 0,
            guideScores: {
            },
            completedIndicators: 0,
            guideDate: null,
            recomendationDocument: '',
            indicators: {
                specific: [],
                transversal: []
            }
        },
        guidesData: [],
        listActiveGuides: [],
        companyData: {
            companyName: '',
            companyNit: '',
            companyImage: ''
        },
        userData: {
            contactName: '',
            contactMail: '',
            contactPhone: ''
        },
        userLogin:{}, 
        dataDashboard:{},
    }
})


@Injectable()
export class AppState {
    constructor(
        private store: Store, 
        private login_services: LoginService,
        private guideService: GuideService, 
        private userInfoService: UserInfoService) { }

    @Selector()
    static selectCurrentGuide(state: AppStateModel) {
        console.log('Current guide', state)
        return state.currentGuideData
    }

    @Selector()
    static selectGuidesData(state: AppStateModel) {
        return state.guidesData
    }

    @Selector()
    static selectInfoUser(state: AppStateModel) {
        return state.userData
    }

    @Selector()
    static selectInfoCompany(state: AppStateModel) {
        return state.companyData
    }

    @Selector()
    static selectDashboard(state: AppStateModel) {
        return state.dataDashboard
    }

    @Selector()
    static selectGuidesActive(state: AppStateModel) {
        return state.listActiveGuides
    }

    @Selector()
    static selectDataLogin(state: AppStateModel) {
        return state.userLogin
    }

    dateYear() {
        const yearActive = localStorage.getItem('yearActive');

        // Obtener la fecha actual
        const today = new Date();

        // Si `yearActive` es válido, reemplazar el año en la fecha actual
        if (yearActive) {
        today.setFullYear(parseInt(yearActive));
        }

        // Crear la fecha modificada
        const createGuideData = today.toISOString();

        console.log(createGuideData);
        return createGuideData;
    }

    @Action(setCurrentGuide)
    setCurrentGuide(ctx: StateContext<AppStateModel>, action: setCurrentGuide) {
        const yearNow = localStorage.getItem('yearActive');
        const currentParentId = action.guideId;
        const state = ctx.getState()
        const { listActiveGuides } = state
        const guideOnProgress = state.guidesData.filter(guide => guide.guideId == currentParentId && guide.guideDate.split("-")[0] == yearNow);

        console.log('Guide on progress', guideOnProgress);
        const storeSnapshot = this.store.selectSnapshot(state => state)
        const { indicators, topics, guidelines, griStandards, cicleStages} = storeSnapshot

        const currentParentName = [...topics.parentTopics].filter(parentTopic => parentTopic.id == currentParentId)[0].name

        const filteredChildTopics = [...topics.childTopics].filter(childTopic => childTopic.parentId == currentParentId)

        const specificIndicators = this.getSpecificIndicators(
            indicators.indicatorsData,
            filteredChildTopics,
            guidelines.guidelinesData,
            griStandards.griStandardsData,
            cicleStages.cicleStagesData
        )

        const progress = this.getGuideProgress({
            indicators: {
                specific: specificIndicators,
                transversal: indicators.transversalIndicators
            }
        })
      
        const activeData = listActiveGuides.filter( activeGuide =>  activeGuide.guideId == currentParentId && activeGuide.yearValidity == yearNow)
       
        let docUrl = ''
        if ( activeData.length > 0) {
           docUrl =  activeData[0].docUrl
         }

        if (guideOnProgress.length > 0) {

            // guideOnProgress[0].guideScores = progress
            guideOnProgress[0].recomendationDocument = docUrl

            // temporal
            const newSpecificIndicators = this.updateSpecificIndicators(specificIndicators, guideOnProgress[0].indicators.specific)
            
            guideOnProgress[0].indicators.specific = newSpecificIndicators
            ctx.patchState({
                currentGuideData: guideOnProgress[0]
            });
        } else {

            ctx.patchState({
                currentGuideData: {
                    guideId: currentParentId,
                    guideName: currentParentName,
                    guideState: 'inProgress',
                    totalIndicators: progress.totalIndicators,
                    guideScores: progress,
                    completedIndicators: progress.completedIndicators,
                    guideDate: this.dateYear(),
                    recomendationDocument: docUrl,
                    indicators: {
                        specific: specificIndicators,
                        transversal: [...indicators.transversalIndicators]
                    }
                }
            });
        }
    }

    @Action(storeCurrentGuide)
    storeCurrentGuide(ctx: StateContext<AppStateModel>, action: storeCurrentGuide) {
        console.log('Action guide send', action.guideIdSend);
        
        const state = ctx.getState()
        const { currentGuideData, guidesData } = state


        if (currentGuideData.guideId !== null && currentGuideData.guideDate.split("-")[0] == localStorage.getItem('yearActive')) {
            console.log('Current guide data', currentGuideData)
            console.log('Guides date', currentGuideData.guideDate.substring(0, 4))
            let isSend = action.guideIdSend;
            let isGuideOnProgress = false
            let guidePos = null
    
            guidesData.forEach((guide, i) => {
                let yearActive = localStorage.getItem('yearActive');
                if (guide.guideId == currentGuideData.guideId && guide.guideDate.split("-")[0] == yearActive) {
                    isGuideOnProgress = true 
                    guidePos = i
                }
            })
    
            const guideProgress = this.getGuideProgress(currentGuideData)
    
            currentGuideData.completedIndicators = guideProgress.completedIndicators
    
            const isCompleted = guideProgress.completedIndicators === guideProgress.totalIndicators && isSend == 'isSend' ? true : false 
            currentGuideData.guideState = isCompleted ? 'complete' : 'inProgress'
            
            currentGuideData.guideDate =  this.dateYear();
            currentGuideData.guideScores = guideProgress;
            
            if (isGuideOnProgress) {
    
                const newGuidesData = [...guidesData]
                newGuidesData[guidePos] = currentGuideData
    
                ctx.patchState({
                    guidesData: [...newGuidesData]
                })
            } else {
    
                ctx.patchState({
                    guidesData: [...state.guidesData, currentGuideData]
                })
            }
    
            const userId = ctx.getState().userLogin['current_user'].uid

            let yearActive = localStorage.getItem('yearActive');

            // // Obtener la fecha actual
            // let today = new Date();

            // // Si `yearActive` es válido, reemplazar el año en la fecha actual
            // if (yearActive) {
            // today.setFullYear(parseInt(yearActive));
            // }

            // // Crear la fecha modificada
            // let guideCreated = today.toISOString();

            // console.log(guideCreated);
    
            const dataToPost = {
                guideData: currentGuideData,
                userId: userId,
                createdAt: moment(this.dateYear()).format("DD/MM/YYYY HH:mm:ss"),
                yearValidity: yearActive
            }
            
            this.guideService.postGuideChanges(dataToPost)
            this.store.dispatch(new setCurrentDashboard(currentGuideData.guideId, parseInt(yearActive)))
        }

        

    }

    @Action(setIndicatorValorations)
    setIndicatorValorations(ctx: StateContext<AppStateModel>, action: setIndicatorValorations) {
        const state = ctx.getState()

        const { currentGuideData } = state

        const { inidicatorId, indicatorType, assessment, observations, attachedDocuments } = action

        let isCompleted = observations && assessment ? true : false
        let isValued = assessment != 0 ? true : false

        if (observations && assessment) { }

        const currentIndicators = indicatorType === 'Transversal' ? [...currentGuideData.indicators.transversal]
            : [...currentGuideData.indicators.specific]

        let indicators = currentGuideData.indicators



        if (indicatorType === 'Transversal') {

            currentIndicators.forEach((indicator, i) => {
                if (indicator.indicatorNumber == inidicatorId) {
                    currentIndicators[i].assessment = assessment
                    currentIndicators[i].observations = observations
                    if (attachedDocuments.length > 0) {
                        /* currentIndicators[i].attachedDocuments = [... currentIndicators[i].attachedDocuments, ...attachedDocuments] */
                        /* currentIndicators[i].attachedDocuments = attachedDocuments */
                    }
                    currentIndicators[i].attachedDocuments = attachedDocuments
                    currentIndicators[i].isCompleted = isCompleted
                    currentIndicators[i].isValued = isValued
                }
            })

            indicators = {
                specific: currentGuideData.indicators.specific,
                transversal: currentIndicators
            }
        } else if (indicatorType === 'Especifico') {

            currentIndicators.forEach((topic) => {
                topic.indicators.forEach((indicator, i) => {
                    if (indicator.indicatorNumber == inidicatorId) {
                        topic.indicators[i].assessment = assessment
                        topic.indicators[i].observations = observations
                        if (attachedDocuments.length > 0) {
                            /* topic.indicators[i].attachedDocuments = [... topic.indicators[i].attachedDocuments, ...attachedDocuments] */
                            /* topic.indicators[i].attachedDocuments = attachedDocuments */
                        }
                        topic.indicators[i].attachedDocuments = attachedDocuments
                        topic.indicators[i].isCompleted = isCompleted
                        topic.indicators[i].isValued = isValued
                    }
                })
            })

            indicators = {
                specific: currentIndicators,
                transversal: currentGuideData.indicators.transversal,
            }
        }

        ctx.patchState({
            currentGuideData: {
                guideId: currentGuideData.guideId,
                guideName: currentGuideData.guideName,
                guideState: currentGuideData.guideState,
                totalIndicators: currentGuideData.totalIndicators,
                guideScores: currentGuideData.guideScores,
                completedIndicators: currentGuideData.completedIndicators,
                guideDate: currentGuideData.guideDate,
                recomendationDocument: currentGuideData.recomendationDocument,
                indicators: indicators
            }
        });
    }

    @Action(getInfoUser)
    getInfoUser(ctx: StateContext<AppStateModel>, action: getInfoUser) {
        const userId = ctx.getState().userLogin['current_user'].uid
        return this.userInfoService.getInfo(userId).pipe(
            tap( info => {
                
                ctx.patchState({
                    companyData: {
                        companyName: info['data']['company']['companyName'][0]['value'],
                        companyNit: info['data']['company']['companyNit'][0]['value'],
                        companyImage: info['data']['company']['companyImage']
                    },
                    userData: {
                        contactName: info['data']['contactPerson']['contactName'][0]['value'],
                        contactMail: info['data']['contactPerson']['contactMail'][0]['value'],
                        contactPhone: info['data']['contactPerson']['contactPhone'][0]['value'],
                    }
                });
            }, error => {
                
            })
        )
    }

    @Action(setUserContactInfo)
    setUserContactInfo(ctx: StateContext<AppStateModel>, action: setUserContactInfo) {
        const state = ctx.getState()
        const { contactName, contactMail, contactPhone } = action
        const userId = state.userLogin['current_user'].uid

        ctx.patchState({
            userData: {
                contactName: contactName !==  '' ?  contactName : state.userData.contactName,
                contactMail: contactMail !==  '' ?  contactMail : state.userData.contactMail,
                contactPhone: contactPhone !==  '' ?  contactPhone : state.userData.contactPhone,
                
            }
        })

        return this.userInfoService.postContactUserInfo(
            userId,
            contactName,
            contactMail,
            contactPhone)
    }

    @Action(getGuides)
    getGuides(ctx: StateContext<AppStateModel>, action: getGuides) {
        const userId = ctx.getState().userLogin['current_user'].uid
        return this.userInfoService.getGuides( userId, action.yearNow ).pipe(
            tap( respGuides => {
                console.log('Guides', respGuides)
                
            /*   const state = ctx.getState()
                const { guidesData } = state

                respGuides['data'].forEach( activeGuide => {
                    guidesData.forEach( guide => {
                        if ( activeGuide.guideId ==  guide.guideId) {
                            guide.recomendationDocument = activeGuide.docUrl
                        }
                    })
                })
 */
                ctx.patchState({
                    listActiveGuides: respGuides['data'],
                   /*  guidesData: guidesData */
                });
            })
        )
    }

    @Action(setLogin)
    setLogin(ctx: StateContext<AppStateModel>, action: setLogin) {
        return this.login_services.getLogin(action.user).pipe(
            tap( respLogin => {
                ctx.patchState({
                    userLogin: respLogin
                });
            })
        )

    }


    @Action(getGuideDataFromDb)
    getGuideDataFromDb(ctx: StateContext<AppStateModel>, action: getGuideDataFromDb) {
        const userId = ctx.getState().userLogin['current_user'].uid
        return this.guideService.getGuideUpdatedData(userId, action.yearValidity).pipe(
            tap( (updatedGuideData : any)=> {
                

                if (updatedGuideData.data.length > 0) {
                    ctx.patchState({
                        guidesData: updatedGuideData.data
                    });
                }
                
            })
        )
    }
        
    @Action(setCurrentDashboard)
    setCurrentDashboard(ctx: StateContext<AppStateModel>, action: setCurrentDashboard){
        
        
        const state = ctx.getState();
        const dataDash = state.guidesData.filter( 
            guide => guide.guideId === action.guideId && guide.guideDate.split('-')[0] == action.yearValidity
            )
        
            
        ctx.patchState({ dataDashboard: dataDash[0].guideScores })
    }
    
    @Action(clearAll)
    clearAll(ctx: StateContext<AppStateModel>, action: clearAll){
        ctx.patchState({
            currentGuideData: {
                guideId: null,
                guideName: '',
                guideState: 'inProgress',
                totalIndicators: 0,
                guideScores: {
                },
                completedIndicators: 0,
                guideDate:  this.dateYear(),
                recomendationDocument: '',
                indicators: {
                    specific: [],
                    transversal: []
                }
            },
            guidesData: [],
            listActiveGuides: [],
            companyData: {
                companyName: '',
                companyNit: '',
                companyImage: ''
            },
            userData: {
                contactName: '',
                contactMail: '',
                contactPhone: ''
            },
            userLogin:{}, 
            dataDashboard:{},
        })
    }

    @Action(updateCurrentGuideData)
    updateCurrentGuideData(ctx: StateContext<AppStateModel>, action: updateCurrentGuideData){
        const state = ctx.getState()

        console.log('Action update', state);
        const { listActiveGuides, guidesData } = state

        console.log('Action update', action)

        console.log('Action store', this.store.selectSnapshot(state => state))

        const storeSnapshot = this.store.selectSnapshot(state => state)
        const { topics } = storeSnapshot

        if( state.currentGuideData.guideId == null ) {
            state.currentGuideData.guideDate = null
        }
        
        if ( listActiveGuides.length > 0 && guidesData.length > 0) {
            listActiveGuides.forEach( activeGuide => {
                let yearActive = localStorage.getItem('yearActive');
                guidesData.forEach( guideData => {
                    if (guideData.guideId == activeGuide.guideId && guideData.guideDate.split("-")[0] == yearActive) {
                    guideData.recomendationDocument = activeGuide.docUrl
                    }
                });
            })
        }

        ctx.patchState({
            guidesData: guidesData
        })
    }

    //HELPERS

    getSpecificIndicators(indicators, childTopics, guideLines, griStandards, cicleStages) {
        let specificIndicators = []
        const _indicators = [...indicators]
        const _childTopics = [...childTopics]
        const _guideLines = [...guideLines]
        const _griStandards = [...griStandards]
        const _cicleStages = [...cicleStages]

        _childTopics.forEach((childTopic, i) => {
            specificIndicators[i] = {
                topic: childTopic.name,
                topicId: childTopic.id,
                indicators: []
            }
        })

        for (let indicator of _indicators) {
            
            for (let i = 0; i < _childTopics.length; i++) {
                
                if (indicator.topic === _childTopics[i].id) {
                    specificIndicators[i].indicators = [...specificIndicators[i].indicators, indicator]
                }
            }
        }

        //DUPLICATE CODE, REFACTOR
        for (let i = 0; i < specificIndicators.length; i++) {
            for (let x = 0; x < specificIndicators[i].indicators.length; x++) {
                if (specificIndicators[i].indicators[x].guideLines.length > 0) {
                    let newGuideLines = []
                    for (let j = 0; j < specificIndicators[i].indicators[x].guideLines.length; j++) {

                        const matchGuideline = _guideLines.filter(storeGuideline => storeGuideline.id == specificIndicators[i].indicators[x].guideLines[j].id)
                        newGuideLines = [...newGuideLines,
                        matchGuideline.length > 0 ? matchGuideline[0] : []
                        ]
                    }
                    if (newGuideLines.length > 0) {
                        specificIndicators[i].indicators[x].guideLines = newGuideLines
                    }
                }
            }
        }


        for (let a = 0; a < specificIndicators.length; a++) {
            for (let b = 0; b < specificIndicators[a].indicators.length; b++) {
                if (specificIndicators[a].indicators[b].griStandards.length > 0) {

                    let newgriStandards = []
                    for (let c = 0; c < specificIndicators[a].indicators[b].griStandards.length; c++) {

                        const matchGuideline = _griStandards.filter(storeGriStandard => storeGriStandard.id == specificIndicators[a].indicators[b].griStandards[c].id)
                        newgriStandards = [...newgriStandards,
                        matchGuideline.length > 0 ? matchGuideline[0] : []
                        ]

                    }
                    if (newgriStandards.length > 0) {
                        specificIndicators[a].indicators[b].griStandards = newgriStandards
                    }
                }
            }
        }


        for (let a = 0; a < specificIndicators.length; a++) {
            for (let b = 0; b < specificIndicators[a].indicators.length; b++) {
                if (specificIndicators[a].indicators[b].cicleStage) {

                    let newCicleStage = undefined

                    const match = _cicleStages.filter(storeCicleStage => storeCicleStage.id == specificIndicators[a].indicators[b].cicleStage)
                    newCicleStage = match.length > 0 ? match[0] : undefined

                    if (newCicleStage) {
                        specificIndicators[a].indicators[b].cicleStage = newCicleStage
                    }
                }
            }
        }

        return specificIndicators

    }

    getGuideProgress(guide) {
        console.log(guide)
        const _specificIndicators = [...guide.indicators.specific]
        const _transversalIndicators = [...guide.indicators.transversal]


        let completedIndicators = 0
        let totalIndicators = 0
        let totalScore = 0
        let specificScore = 0
        let transversalScore = 0
        let childTopicsScore = []
        let cicleStagesScore = []
        let valuedTransversalIndicators  = 0 
        let valuedSpecificIndicators  = 0 

        const storeSnapshot = this.store.selectSnapshot(state => state)

        const cicleStages = storeSnapshot.cicleStages.cicleStagesData

       cicleStages.forEach( stage => {
            cicleStagesScore = [...cicleStagesScore, { id: stage.id, name: stage.name, score: 0, valued: 0, implementacionPercentage: 0 } ] 
        });

        _transversalIndicators.forEach(transversalIndicator => {
            totalIndicators++
            if (transversalIndicator.assessment) {
                transversalScore += parseInt(transversalIndicator.assessment)
                totalScore += parseInt(transversalIndicator.assessment)
            }

            if ( transversalIndicator.isValued ) {
                valuedTransversalIndicators++
            }
           
            if (transversalIndicator.isCompleted) {
                completedIndicators++
            }
        });

        _specificIndicators.forEach(topic => {
            let childTopicScore = 0
            topic.indicators.forEach(specificiIndicator => {
               
                totalIndicators++
                if (specificiIndicator.assessment) {
                    specificScore += parseInt(specificiIndicator.assessment)
                    totalScore += parseInt(specificiIndicator.assessment)
                    childTopicScore += parseInt(specificiIndicator.assessment)
                    cicleStagesScore.forEach( (stage, i ) => {
                        if (stage.id == specificiIndicator.cicleStage.id) {
                            cicleStagesScore[i].score += parseInt(specificiIndicator.assessment)
                            if (specificiIndicator.isValued) { 
                                cicleStagesScore[i].valued += 1
                            }
                        }
                    })
                }
               
                if (specificiIndicator.isCompleted) {
                    completedIndicators++
                }

                if (specificiIndicator.isValued) {
                    valuedSpecificIndicators++
                }

               
            });
            childTopicsScore = [...childTopicsScore, { topic: topic.topic, score: childTopicScore} ]  
        });

        const transversalsPercentage = Math.ceil((transversalScore * 100)  / (valuedTransversalIndicators * 3))
        const specificPercentage = Math.ceil((specificScore * 100) / (valuedSpecificIndicators * 3))
       
        cicleStagesScore.forEach( (stage, i ) => {
            const percentage =  Math.ceil((stage.score * 100) / (stage.valued * 3))
            cicleStagesScore[i].implementacionPercentage =  !isNaN(percentage) ? percentage : 0
        });

      

        return {
            completedIndicators,
            totalIndicators,
            specificScore,
            transversalScore,
            totalScore,
            childTopicsScore,
            cicleStagesScore,
            transversalsPercentage,
            specificPercentage
        }

    }

    updateSpecificIndicators  (specificiIndicators, currentIndicators) {

        currentIndicators.forEach( currentTopic => {
            currentTopic.indicators.forEach( currentIndicator => {
                specificiIndicators.forEach( specificTopic => {
                    if (currentTopic.topicId == specificTopic.topicId ) {
                        specificTopic.indicators.forEach( specificiIndicator => {
                            if ( currentIndicator.indicatorNumber == specificiIndicator.indicatorNumber) {
                                
                                currentIndicator.diagnosticIndicator = specificiIndicator.diagnosticIndicator
                                currentIndicator.guideLines = specificiIndicator.guideLines
                            }
                        });
                    }
                }); 
            });
            
        })
        
        return currentIndicators
    }
}
