<app-header></app-header>
<router-outlet></router-outlet>
<section class="banner">
	<h1><mark><i>Aplicativo de</i> Guías Colombia</mark><span>Herramienta de autodiagnóstico para la debida diligencia empresarial en derechos humanos</span></h1>
	<!-- <a [routerLink]="['login']" routerLinkActive="active" class="login-btn">Inciar seción</a> -->
</section>
<section id="section1" class="sections seccion1">
	<div class="container">
	<h3>¿Qué es la Herramienta de autodiagnóstico para la identificación de brechas de gestión en derechos humanos?</h3>
	<p>Es un instrumento de autodiagnóstico y de gestión organizacional de uso exclusivo para las empresas miembro de Guías Colombia que valora sus avances en materia de gestión en derechos humanos y señala las medidas de gestión que aún deben tomarse desde la empresa para asegurar el cumplimiento de su deber en debida diligencia en derechos humanos.</p>
	</div>

</section>
<section id="section2" class="sections seccion2">
	<div class="container">
	<h3>¿Qué obtienen las empresas?</h3>
	<ul>
		<li>Identificación de brechas de gestión en derechos humanos.</li>
		<li>Indicadores transversales en debida diligencia empresarial en derechos humanos.</li>
		<li>Indicadores específicos asociados a cada una de las Guías Colombia.</li>
		<li>Reportes estadísticos de avance en la implementación de estándares en materia de debida diligencia empresarial en derechos humanos.</li>
		<li>Recomendaciones para el cierre de brechas en debida diligencia empresarial en derechos humanos.</li>
	</ul>
</div>
</section>
<section id="section3" class="sections seccion3">
	<div class="container">
	<h3>Integrantes</h3>
		  <owl-carousel-o [options]="customOptions">    
		  <ng-template carouselSlide><img src="assets/img/logos/andi.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/anglo.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/anh.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/arn.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/ccb.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/canada.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/cenit.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/cgt.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/aconce.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/crecer.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/defenso.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/eco.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/epm.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/geopack.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/abb.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/movis.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/nu.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/nestle.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/oim.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/prodeco.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/promigas.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/pacto.png" width="100"></ng-template>
		  <ng-template carouselSlide><img src="assets/img/logos/redepaz.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/renacer.png" width="100"></ng-template>  
		  <ng-template carouselSlide><img src="assets/img/logos/sigma.png" width="100"></ng-template>  
		  <ng-template carouselSlide><img src="assets/img/logos/suiza.png" width="100"></ng-template> 
		  <ng-template carouselSlide><img src="assets/img/logos/tipiel.png" width="100"></ng-template>  
		  <ng-template carouselSlide><img src="assets/img/logos/unidad.png" width="100"></ng-template>             
		</owl-carousel-o> 
	</div>
</section>
<section id="section4" class="sections seccion4">
	<div class="container">
	<h3>Contáctenos</h3>
	<div class="body-flex">
		<div class="contat-form">
			<form #form="ngForm">
				<mat-form-field class="full">
					<mat-label>Nombre</mat-label>
					<input matInput >
				</mat-form-field>
				<div class="body-flex">
				<mat-form-field class="float">
					<mat-label>Email</mat-label>
					<input matInput>
				</mat-form-field>
			  
				<mat-form-field class="float">
					<mat-label>Teléfono</mat-label>
					<input matInput>
				</mat-form-field>
			</div>
				<mat-form-field>
					<mat-label>Mensaje</mat-label>
					<textarea matInput></textarea>
				</mat-form-field>
				<button _ngcontent-gbi-c32="" mat-raised-button="" color="secundary" class="mat-raised-button mat-button-base mat-secundary" ng-reflect-color="secundary" tabindex="0" ng-reflect-router-link="login"><span class="mat-button-wrapper">Enviar</span><div matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></div><div class="mat-button-focus-overlay"></div></button>
			  </form>
			</div>
	<div class="contat-info">
	<p>Para cualquier inquietud sobre el uso y funcionalidad de la Herramienta, favor contactar a la Secretaría Técnica de Guías Colombia, ejercida por la Fundación Ideas para la Paz (FIP), a:
guiascolombia@ideaspaz.org / + 57 (1) 747 0273.
</p>
<p> Conozca más en: </p>
<p><a target="_blank" href="http://ideaspaz.org">Página web de la FIP.</a></p>
<p><a target="_blank" href="http://empresaspazddhh.ideaspaz.org/guias-colombia">Micrositio de empresas, paz y DDHH</a></p>
</div>
<!-- form goes here -->
</div>
<div class="redes-footer">
<label>Síganos en:</label><a class="twitter" target="_blank" href="https://twitter.com/GuiasCol">Twitter</a>
</div>
	</div>
</section>