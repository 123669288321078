import { State, StateContext, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
   InitializeGriStandards
} from './../actions/gri-standards.action';
import { GriStandardsService } from './../../services/gri-standards.service';
import { Injectable } from '@angular/core';

import { htmlTextRemove } from './../../helpers/stringsHelper';

export interface GriStandardsStateModel {
    griStandardsData: Array<any>
}

@State<GriStandardsStateModel>({
    name: 'griStandards',
    defaults: {
        griStandardsData: [],
    }
})

@Injectable()
export class GriStandardsState {
    constructor(private griStandardsService: GriStandardsService) { }

  /*   @Selector()
    static selectTopics(state: GriStandardsStateModel) {
        return state.topicsData
    } */

    @Action(InitializeGriStandards)
    initializeGriStandards(ctx: StateContext<GriStandardsStateModel>, action: InitializeGriStandards) {
        return this.griStandardsService.getGriStandards().pipe(
            tap(griStandardsResult => {
                const griStandardsArray = Object.values(griStandardsResult)
               
                const mappedData = griStandardsArray.map(griStandard => {
                    return {
                        id: griStandard.tid[0].value,
                        name: griStandard.name[0].value,
                        description: htmlTextRemove(griStandard.description[0].value)
                    }
                })
              
                ctx.patchState({
                    griStandardsData: mappedData
                });
            })
        );
    }
}
