import { Injectable } from '@angular/core';
import { ENV_DEV } from '../constants/config';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private HOST: string = ENV_DEV.LOGIN_URL

  constructor( private httpClient: HttpClient) { }

  getLogin( user : UserModel ){
    
    const ENDPOINT = 'user/login?_format=json'
    return this.httpClient.post(`${this.HOST}${ENDPOINT}`, user)
  }
}
