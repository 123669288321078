<section>
    <h2>Mis Aplicaciones</h2>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Guía Colombia </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{(element.date | date) | formatdate | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="points">
            <th mat-header-cell *matHeaderCellDef> Puntuación </th>
            <td mat-cell *matCellDef="let element"> {{element.points}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="resul">
            <th mat-header-cell *matHeaderCellDef> Reporte de resultados </th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="element.status == 'Completado'; else continueGuide" mat-stroked-button color="primary" (click)="clickView(element)">Ver</button>
                <ng-template #continueGuide>
                    <button mat-stroked-button color="primary" [ngClass]="{'disabled':element.date.substring(0, 4) != this.guidesActive}"  (click)="clickView(element)">Continuar</button>
                </ng-template>
                
            </td>
        </ng-container>

        <ng-container matColumnDef="recom">
            <th mat-header-cell *matHeaderCellDef> Informe de recomendaciones</th>
            <td mat-cell *matCellDef="let element">
                <button mat-mini-fab (click)="downloadFile(element.fileUrl)" class="buttonfile" [ngClass]="{'active': element.statusFile}">
                    <mat-icon>arrow_downward</mat-icon>
                  </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</section>