import { State, StateContext, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { 
    InitializeTopics, 
    InitializeChildTopics, 
    InitializeParentTopics 
} from './../actions/topics.actions';
import { TopicsService } from './../../services/topics.service';
import { Injectable } from '@angular/core';


export interface TopicsStateModel {
    topicsData: Array<any>
    childTopics: Array<any>
    parentTopics: Array<any>
}

@State<TopicsStateModel>({
    name: 'topics',
    defaults: {
        topicsData: [],
        childTopics: [],
        parentTopics: []
    }
})


@Injectable()
export class TopicsState {
    constructor(private topicsService: TopicsService) { }

    @Selector()
    static selectTopics(state: TopicsStateModel) {
        return state.topicsData
    }

    @Selector()
    static selectParentTopics(state: TopicsStateModel) {
        return state.parentTopics
    }

    @Action(InitializeTopics)
    initializeTopics(ctx: StateContext<TopicsStateModel>, action: InitializeTopics) {
        return this.topicsService.getTopics().pipe(
            tap(topicsResult => {
              
                const topicsArray = Object.values(topicsResult)
                
                const mappedData = topicsArray.map(topic => {
                    return {
                        id: topic.tid[0].value,
                        name: topic.name[0].value,
                        imageUrl: topic['field_topic_image'].length > 0 ? topic['field_topic_image'][0].url : []
                    }
                })

                ctx.patchState({
                    topicsData: mappedData
                });
            })
        );
    }

    @Action(InitializeParentTopics)
    initializeParentTopics(ctx: StateContext<TopicsStateModel>, action: InitializeParentTopics) {
        return this.topicsService.getTopics().pipe(
            tap(topicsResult => {
                const topicsArray = Object.values(topicsResult)
                const parentTopics = topicsArray.filter(topic => topic.parent[0]['target_id'] === null)
                
                const mappedData = parentTopics.map(topic => {
                    return {
                        id: topic.tid[0].value,
                        name: topic.name[0].value,
                        imageUrl: topic['field_topic_image'].length > 0 ? topic['field_topic_image'][0].url : "",
                        deadLineData: topic['field_deadline'].length > 0 ? topic['field_deadline'][0].value : ""
                    }
                })

                ctx.patchState({
                    parentTopics: mappedData
                });
            })
        );
    }

    @Action(InitializeChildTopics)
    initializeChildTopics(ctx: StateContext<TopicsStateModel>, action: InitializeChildTopics) {
        return this.topicsService.getTopics().pipe(
            tap(topicsResult => {
                const topicsArray = Object.values(topicsResult)
                const childsTopics = topicsArray.filter(topic => topic.parent[0]['target_id'] !== null)
                const mappedData = childsTopics.map(topic => {
                    return {
                        id: topic.tid[0].value,
                        name: topic.name[0].value,
                        parentId: topic.parent[0]['target_id'],
                        imageUrl: topic['field_topic_image'].length > 0 ? topic['field_topic_image'][0].url : []
                    }
                })

                ctx.patchState({
                    childTopics: mappedData
                });
            })
        );
    }
}