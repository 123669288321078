import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/class/errorForm';
import { Store } from '@ngxs/store';
import { setUserContactInfo } from 'src/app/store/actions/app-state.action';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input('userInfo') userInfo: Array<any>

  name: string;
  email: string;
  tel: number;

  formPerson: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor( private formBuilder: FormBuilder, private store: Store ) { }

  ngOnInit(): void {
    this.getInfoUser();
    this.formPerson = this.formBuilder.group({
      name: [{value: this.name, disabled: true}, [Validators.required]],
      email: [{value: this.email, disabled: true}, [Validators.required]],
      tel: [{value: this.tel, disabled: true}, [Validators.required]]
    })
  }

  get formP() { return this.formPerson.controls; }

  getInfoUser() {
    this.name = this.userInfo['contactName'];
    this.email = this.userInfo['contactMail'];
    this.tel = this.userInfo['contactPhone'];
  }

  saveData( form ){
    
    if ( form.valid ) {
      this.formPerson.get('name').disable();
      this.formPerson.get('email').disable();
      this.formPerson.get('tel').disable();
      
      const { name, email, tel} = form.value
      this.store.dispatch(new setUserContactInfo(name, email, tel)).subscribe( response => {
        
      })
    }
  }

  changeData() {
    this.formPerson.get('name').enable();
    this.formPerson.get('email').enable();
    this.formPerson.get('tel').enable();
  }

}
