import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_DEV } from './../constants/config';

@Injectable({
  providedIn: 'root'
})
export class RectorsPrinciplesService {
  
  private HOST: string = ENV_DEV.URL;

  constructor( private httpClient: HttpClient ) { }

  getRectorsPrinciples() {
    const ENDPOINT = 'rectors-principles';
    return this.httpClient.get(`${this.HOST}${ENDPOINT}`)
  }
}



