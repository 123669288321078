import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { AssessmentsState } from '../../store/states/assessments.state';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { setIndicatorValorations } from '../../store/actions/app-state.action';
import { MyErrorStateMatcher } from '../../class/errorForm';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileService } from '../../services/file.service';
import { ModalDialog } from '../../class/modalDialog';
import { Loading } from '../../class/loading';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Select(AssessmentsState.selectAssessments) itemAssess$: Observable<any>;


  id: number;
  desc: string;
  stantdards: any[];
  lines: any[];
  rectors: any[];
  circle: any;
  assessment: any[];
  type: string;
  observa: string;
  assessmentId: number;

  formIndicator: FormGroup;

  matcher = new MyErrorStateMatcher();
  submitted: boolean;

  stateCompleted: boolean;

  files: NgxFileDropEntry[] = [];

  indicatorFile: object
  documentUrl: string
  haveDocument: boolean = false
  arrayOfFiles = []
  arrayOfFilesData = []
  uploadedFiles = []


  modalDialog = new ModalDialog();
  loading = new Loading();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalComponent>,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit(): void {
    this.viewIfo();
    this.getAssessments();

    this.submitted = false;
    this.formIndicator = this.formBuilder.group({
      observations: [this.observa, [Validators.required]],
      assessments: [this.assessmentId, [Validators.required]],
      file: [this.arrayOfFiles, []]
    })
  }

  viewIfo() {
    // const trans = this.data['trans']
    this.id = this.data['itemList'];
    this.desc = this.data['itemDiac'];
    this.stantdards = this.data['itemStant'];
    
    this.lines = this.data['itemLines'];
    
    this.rectors = this.data['itemRectors'];
    this.circle = this.data['itemCicle'];
    this.type = this.data['itemType'];
    this.observa = this.data['itemObservations'],
    this.assessmentId = this.data['itemAssessment']
    this.stateCompleted = this.data['itemState']
    this.uploadedFiles = this.data['itemDocument']
    this.files =  this.uploadedFiles
    this.haveDocument  =  this.uploadedFiles.length > 0 ? true : false
  }

  get formInd() { return this.formIndicator.controls; }

  getAssessments() {
    this.itemAssess$
      .subscribe(resp => {
        this.assessment = resp;
      })
  }

  clickVar(value, $e) {
    $e.preventDefault()
  }

  async save(form) {
    this.submitted = true;
    if (form.valid) {

      this.dialogRef.close({ dataState: this.stateCompleted });
     
      this.loading.viewLoading();

      let resultFiles = []
      if (this.arrayOfFiles.length > 0) {
        for (const file of this.arrayOfFiles) {
          
           
           const result = await this.uploadFile(file)
          
          
           if ( result['data'] ) {
             resultFiles = [...resultFiles, result['data']]
           }
           
         }
        
        
     
        
      }

      this.store.dispatch(new setIndicatorValorations(
        this.type,
        this.id,
        form['value']['assessments'],
        form['value']['observations'],
        [...resultFiles, ...this.uploadedFiles]
      ))
     
      this.loading.closeLoading()
     
      this.modalDialog.openDialog('Guardado!', 'No olvide guardar los cambios al finalizar', 'info')
      this.arrayOfFiles = []
      this.arrayOfFilesData = []
     
    } else {
      this.submitted = true;
      this.modalDialog.openDialog('No se Guardo!', 'No se ha guardado la información', 'error')
    }
  }

  async uploadFile (file) {
   return new Promise ( (resolve, reject) => {
    this.fileService.uploadFile(file).subscribe( uploadResponse => {
     

      
      return resolve(uploadResponse)
    }, error => {
      this.submitted = true;
      this.modalDialog.openDialog('Ha Ocurrido un error!', 'Ha ocurrido un error con el servidor y no se ha guardado la información', 'error')
     
      return reject(error)
    })
   })
   
   
  }

  public dropped(files: NgxFileDropEntry[]) {
    
    this.files = files;
   
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileReader(file)
         
          this.arrayOfFiles = [...this.arrayOfFiles, file]
         
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
       
      }
    }
  }

  public fileOver(event){
   
  }
 
  public fileLeave(event){
   
  }

  fileReader(file) {
    if (file.size <= 5000000 ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {   
        const title = file.name.split('.')[0]
        const extension = file.name.split('.')[1]
        this.arrayOfFilesData = [...this.arrayOfFilesData, {
          fileData: (reader.result as string).split(',')[1],
          title,
          extension
        }] 
      };
    } else {
      Swal.fire({
        text: 'El peso maximo permitido de un archivo es 5 MB, intente con otro archivo',
        icon: 'info',
        showCloseButton: true,
      })
    }

    
   
  }

  removeFileup(receivedIndex) {
    this.arrayOfFiles = this.arrayOfFiles.filter( (file, index) => index != receivedIndex )
  }

  removeFile(receivedIndex) {
    this.uploadedFiles = this.uploadedFiles.filter( (file, index) => index != receivedIndex )
  }

}
