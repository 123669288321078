import { Component, OnInit } from '@angular/core';
import { AppState } from '../../store/states/app-state.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GuideService} from '../../services/guide.service'

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  // @Select(AppState.selectCurrentGuide) itemData$: Observable<any>;
  @Select(AppState.selectGuidesData) itemData$: Observable<any>;

  tableResul: any = [];

  id: string;
  dateGuide: string;
  uidNu: string;

  indEspecific: [];
  indTransver: [];
  

  // \@Select(AppState.selectResult) itemData$: Observable<any>;

  constructor(private guideService: GuideService) {
  }

  ngOnInit() {
    const jasonLog = JSON.parse(localStorage.getItem('@@STATE'))
    console.log('jasonLog', jasonLog)
    // this.id = jasonLog.appState.guidesData[0].guideId;
    // this.dateGuide = jasonLog.appState.guidesData[0].guideDate.substr(0, 4);
    this.uidNu = jasonLog.appState.userLogin.current_user.uid;
    console.log(this.uidNu);
    this.loadResult();
  }

  loadResult() {
    console.log('item', this.itemData$);
    this.id = localStorage.getItem('guideReportId');
    this.dateGuide = localStorage.getItem('gideReportYear').split('-')[0];
   
    return this.guideService.getSingleGuide(this.uidNu,this.dateGuide,this.id).subscribe((data: {}) => {
      this.tableResul = data;
      this.indEspecific = data['data'][0].indicators.specific;
      this.indTransver = data['data'][0].indicators.transversal;
      
    })
  }

  

}

