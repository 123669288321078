import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Store, Select } from '@ngxs/store';
import {
  InitializeTopics,
  InitializeChildTopics,
  InitializeParentTopics
} from './../../store/actions/topics.actions';
import { TopicsState } from 'src/app/store/states/topics.state';
import { InitializeIndicators } from './../../store/actions/indicators.action';
import { InitializeAssessments } from './../../store/actions/assessments.action';
import { InitializeCicleStages } from './../../store/actions/cicle-stages.action';
import { InitializeGuidelines } from './../../store/actions/guidelines.action';
import { InitializeGriStandards } from './../../store/actions/gri-standards.action';
import { InitializeRectorsPrinciples } from './../../store/actions/rectors-principles.action';
import { getGuideDataFromDb, getGuides } from '../../store/actions/app-state.action';
import { AppState } from '../../store/states/app-state.state';
import { Router } from '@angular/router';
import { Session } from '../../class/session';
import * as moment from 'moment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public topics: Array<any> = []

  session = new Session();

  @Select(TopicsState.selectTopics) topics$: Observable<any>;
  @Select(TopicsState.selectParentTopics) parentTopics$: Observable<any>;
  @Select(AppState.selectGuidesData) itemData$: Observable<any>;
  @Select(AppState.selectGuidesActive) guidesActive$: Observable<any>;


  guidesData = new Array;

  stateGuide: boolean = true;
  yearNow: number;

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
    this.yearNow = parseInt(localStorage.getItem('yearActive'));
    console.log('Year now', this.yearNow);
    this.session.validateUser(this.router);
    this.statusGuide();
    this.initializeStore();
    
  }

  initializeStore() {
    this.store.dispatch(new InitializeTopics());
    this.store.dispatch(new InitializeChildTopics());
    this.store.dispatch(new InitializeParentTopics());

    this.store.dispatch(new InitializeAssessments());
    this.store.dispatch(new InitializeCicleStages());
    this.store.dispatch(new InitializeGuidelines());
    this.store.dispatch(new InitializeGriStandards());
    this.store.dispatch(new InitializeRectorsPrinciples());
    this.store.dispatch(new InitializeIndicators());
    this.store.dispatch(
      new getGuides(this.yearNow)
    )


    this.parentTopics$.subscribe(storeParentTopics => {

      if (storeParentTopics.length <= 0) {
        this.store.dispatch(new InitializeParentTopics());
      } else {
        this.topics = storeParentTopics.length > 0 ? storeParentTopics : []
      }

      
      this.guideActive();

    })
  }

  statusGuide() {
    this.itemData$
      .subscribe(resp => {
        console.log('Guides data', resp, this.yearNow, resp[0].guideDate.split("-")[0]);

        // resp.forEach(element => {
        //   if(resp[0].guideDate.split("-")[0] == this.yearNow) {
        //     this.guidesData = resp;
        //     console.log('Guide data', this.guidesData);
        //   }
        // });

        this.guidesData = resp.filter(guide => guide.guideDate.split("-")[0] == this.yearNow);

        console.log('Guide data', this.guidesData);
        
        

      })
  }

  guideActive() {
    let activeGuides = []
    
    this.guidesActive$
      .subscribe(resp => {

        console.log('Active guides', resp);
        
        activeGuides = resp

        console.log('Actives', activeGuides);

        this.topics.forEach((topic, i) => {

          console.log('Topic', topic);
          
          topic.isActive = false
          activeGuides.forEach(activeGuide => {
            
            const now = moment(new Date()).format("DD/MM/YYYY");
            
            let nowDate, topicDeadlineDate
            topic.deadLineData = topic.deadLineData === '' ? now :topic.deadLineData
            if (topic.deadLineData !== '') {
              nowDate = moment(now, "DD-MM-YYYY").toDate()
              topicDeadlineDate = moment(topic.deadLineData, "DD-MM-YYYY").toDate()
            } else {

             }
            
            
            if (topic.id === parseInt(activeGuide.guideId) && (nowDate <= topicDeadlineDate)) {
              topic.isActive = true
            }
          
            if(this.guidesData.length > 0) {
              const yearNow = this.yearNow;
              const theGuide = this.guidesData.filter(guide => guide.guideId == topic.id && guide.guideDate.split("-")[0] == yearNow)[0];
              console.log('log', theGuide);
              console.log('The guides', theGuide)
              if(theGuide) {
                if ( theGuide.guideState == 'complete'){
                  topic.isActive = false
                }
              }
              

            }
            
           
          })
        })
      })

  }

}
