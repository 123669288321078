<header [ngClass]="{'home-header': !hidden}">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <div class="image" [routerLink]="['/']">
                <img src="assets/img/logo.svg" alt="">
                <mark *ngIf="!hidden;">Aplicativo</mark>
            </div>
            <div class="menu" *ngIf="hidden; else loggedOut">
                <ul>
                    <li class="sesion-year">
                        Sesión: {{yerActive}}
                    </li>
                    <li>
                        <a [routerLink]="['/mi-perfil']" [queryParams]="{ year: this.yerActive}">Mi perfil</a>
                    </li>
                    <li>
                        <a (click)="logout()">Cerrar sesión</a>
                    </li>
                </ul>
            </div>
            <ng-template #loggedOut>
            <div class="menu">
                <ul>
                    <li><a href="#section1" routerLinkActive="active" class="item-menu">¿Qué es la Herramienta?</a></li>
                    <li><a href="#section2" routerLinkActive="active" class="item-menu">¿Por qué aplicarla?</a></li>
                    <li><a href="#section3" routerLinkActive="active" class="item-menu">Integrantes</a></li>
                    <li><a href="#section4" routerLinkActive="active" class="item-menu">Contáctenos</a></li> 
                    <li><button mat-raised-button [routerLink]="['login']" color="secundary">Iniciar sesión</button></li>
                </ul>
            </div>
        </ng-template>
        </mat-toolbar-row>
    </mat-toolbar>
</header>