<div class="modal">
    
    <div mat-dialog-content>
        <h1 mat-dialog-title>Indicador <span>{{id}}</span> </h1>
        <div class="modal__info">
            <h2>Indicador de diagnostico</h2>
            <p>{{desc}}</p>
        </div>
        <div class="modal__content">
            <div class="cont-left">
                <form [formGroup]="formIndicator" (ngSubmit)="save( form )" #form="ngForm">
                    <div class="form__assessment">
                        <span>Valoracion</span>
                        <div class="assessment__item">
                            <mat-button-toggle-group formControlName="assessments" name="assessments">
                                <mat-button-toggle *ngFor="let item of assessment" [value]="item.name" matTooltip="{{item.description}}" (click)="clickVar(item.name, $event)" [ngClass]="{'blue': item.name == 0, 'red': item.name == 1, 'yellow': item.name == 2, 'green': item.name == 3}">
                                    <span>{{item.name}}</span>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                            <mat-error *ngIf="submitted && !!formInd['assessments'].errors">
                                <mat-error *ngIf="!!formInd['assessments'].errors.required">Este campo es obligatorio</mat-error>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form__observ">
                        <mat-form-field class="example-full-width">
                            <mat-label>Justificación de la Valoración</mat-label>
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" formControlName="observations" name="observations" [errorStateMatcher]="matcher" [ngClass]="{ 'is-invalid': submitted && formInd.observations.errors }"> </textarea>
                        </mat-form-field>
                        <mat-error *ngIf="submitted && !!formInd['observations'].errors">
                            <mat-error *ngIf="!!formInd['observations'].errors.required">Este campo es obligatorio</mat-error>
                        </mat-error>
                    </div>
                </form>
            </div>
            <div class="cont-right">
                <div class="standards">
                    <span>Estandares GRI</span>
                    <div class="button stand-buttons">
                        <a *ngFor="let item of stantdards" matTooltip="{{item.name}}:  {{item.description}}" aria-label="">
                            {{item.name}}
                        </a>
                    </div>
                </div>
                <div class="guidelines" *ngIf="lines.length > 0">
                    <span>Lineamientos Guias Colombia</span>
                    <div class="button stand-buttons">
                        <a *ngFor="let item of lines" matTooltip="{{item.description}}" aria-label="">
                            {{item.name}}
                        </a>
                    </div>
                </div>
                <div class="rectors" *ngIf="rectors.length > 0">
                    <span>Principios Rectores</span>
                    <div class="button stand-buttons">
                        <a *ngFor="let item of rectors" matTooltip="{{item.description}}" aria-label="">
                            {{item.name}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal__content file-zone">
            <div class="cont-left">
        <div class="file">
            <span>Adjuntar <i>Archivo: peso max: 5MB; formatos permitidos (pdf,docx, ppt, xls)</i></span>
            <ngx-file-drop class="file-content" accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet "
                dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [showBrowseBtn]="true" name="file" formControlName="file" multiple="true" ngDefaultControl>
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    Cargar Archivos
                    <button class="file-button" type="button" (click)="openFileSelector()">Cargar</button>
                </ng-template>
            </ngx-file-drop>
            <mat-error *ngIf="submitted && !!formInd['file'].errors">
                <mat-error *ngIf="!!formInd['file'].errors.required">Este campo es obligatorio</mat-error>
            </mat-error>
        </div>
    </div>
    <div class="cont-right">
        <div class="upload-table">
            <div class="table">
                            <p *ngFor="let item of arrayOfFilesData; let i=index" target="_blank" class="{{ item.extension }} upload-file">
                                {{ item.title }}.{{item.extension}}<a style="cursor: pointer;" (click)="removeFileup(i)"><mat-icon>close</mat-icon></a>
                                
                            </p>
            </div>
        </div>
            <div class="upload-result">
                <div class="upload-content" *ngIf="haveDocument">
                        <h3 mat-subheader>Archivos adjuntos</h3>
                        <p *ngFor="let file of uploadedFiles; let i = index" class="upload-file">
                            {{file.name}}
                            <a [href]="file.url" target="_blank">
                                <mat-icon>arrow_downward</mat-icon>
                            </a>
                            <a style="cursor: pointer;" (click)="removeFile(i)"><mat-icon>close</mat-icon></a>
                            
                        </p>
                </div>

            </div>
        </div>
    </div>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button [mat-dialog-close]="">Cancelar</button>
        <button mat-raised-button color="secundary" cdkFocusInitial (click)="save( form )">Guardar Cambios</button>
    </div>
</div>