import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';
import { AppState } from '../../store/states/app-state.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { Store } from '@ngxs/store';
import { storeCurrentGuide} from 'src/app/store/actions/app-state.action';
import { ModalDialog } from '../../class/modalDialog';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  
  panelOpenState = false;
  @Select(AppState.selectCurrentGuide) itemData$: Observable<any>;

  constructor(public dialog: MatDialog, private router: Router, private store: Store) { }

  nameGuide: string;
  transversal: any[];
  specific: any[];
  totalQues: number;
  dateitem: Date;
  totalCompletedItem: number;
  modalDialog = new ModalDialog();
  guideIsSend = false;
  indicatorsAreCompleted = false;
  displayedColumns: string[] = ['check','title', 'ratings', 'cycleStage', 'edit'];

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
    console.log('PRUEBA /////', this.itemData$);
    let activeYear = localStorage.getItem('yearActive');
    this.itemData$
    .subscribe( resp => {
      console.log('RESP', resp);
      this.nameGuide = resp['guideName'];
      const indicators = resp['indicators'];
      this.transversal = indicators['transversal'];
      this.specific = indicators['specific'];
      this.totalQues = resp['totalIndicators'];
      
      this.dateitem = resp['guideDate'];
      
      this.totalCompletedItem = resp['completedIndicators']
      this.totalCompleted();
      });
  }

  itemSpecific( ) {
    const queSpecific = new Array;
    console.log(queSpecific);
    let itemsSpecific: number;
    this.specific.forEach(element => {
      const itemSpe = element['indicators'].length;
      queSpecific.push(itemSpe);
    });
    return itemsSpecific = queSpecific.reduce((a, b) => a + b, 0);
  }

  totalCompleted() {
    const arrayTransvesal = new Array;
    const arraySpecific = new Array;
    this.totalCompletedTranversal(arrayTransvesal);
    this.totalCompletedSpecific(arraySpecific)
    this.totalCompletedItem = arrayTransvesal.length + arraySpecific.length;
    if( this.totalCompletedItem === this.totalQues) { 
        this.indicatorsAreCompleted = true
    }
  }

  totalCompletedTranversal( arrayCompleted ) {
    this.transversal.forEach( element => {
      if( element['isCompleted'] === true ){
        arrayCompleted.push(element);
      }
    });
  }
  
  totalCompletedSpecific( arrayCompleted ) {
    this.specific.forEach(topic => {
      topic['indicators'].forEach(element => {
        if( element['isCompleted'] === true){
          arrayCompleted.push(element);
        }
      });
    });
  }

  openDialog( item ): void {
    const itemList = item['indicatorNumber'];
    const itemDiac = item['diagnosticIndicator'];
    const itemStant = item['griStandards'];
    const itemLines = item['guideLines'];
    const itemRectors = item['rectorsPrinciples'];
    const itemCicle = item['cicleStage'];
    const itemType = item ['type'];
    const itemObservations = item['observations'];
    const itemAssessment = item['assessment'];
    const itemState = item['isCompleted']
    const itemDocument = item['attachedDocuments']
    
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '100%',
      data: {
          itemList: itemList,
          itemDiac: itemDiac, 
          itemStant: itemStant,
          itemLines: itemLines,
          itemRectors: itemRectors,
          itemCicle: itemCicle,
          itemType: itemType,
          itemObservations: itemObservations,
          itemAssessment: itemAssessment,
          itemState: itemState,
          itemDocument
      }
    });

    dialogRef.afterClosed().subscribe( result => {
    });
  }

  clickCancel(){
    this.router.navigateByUrl('/home');
  }

  clickSave(){
    this.modalDialog.openDialog('Guardado!', 'Se ha guardado la información con exitó', 'success')
    this.store.dispatch( 
      new storeCurrentGuide('noSend'))
      .subscribe( result => {
        console.log(result)
        if( this.totalCompletedItem === this.totalQues ) {
          this.router.navigateByUrl('/home');
        }
    })
  }

  clickSend(){
    this.guideIsSend = true;
    this.modalDialog.openDialog('Enviado a la secretaría!', 'Su información ha sido enviada a la secretaría', 'success')
    this.store.dispatch( 
      new storeCurrentGuide('isSend'))
      .subscribe( result => {
        console.log(result)
        if( this.totalCompletedItem === this.totalQues ) {
          this.router.navigateByUrl('/dashboard');
        }
    })
  }
}
