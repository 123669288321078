import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/class/errorForm';
import { ResetPassService } from '../../services/reset-pass.service'
import { Loading } from '../../class/loading';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  ResetForm: FormGroup;
  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  loading = new Loading();
  matcher = new MyErrorStateMatcher();

  constructor(private resetPassService: ResetPassService,
    private router: Router,) { }

  ngOnInit(): void {
    this.ResetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email], this.forbiddenEmails),
    });
  }

  get formL() { return this.ResetForm.controls; }

  RequestResetPass(form) {
    
    if (form.valid) {
      this.IsvalidForm = true;
      this.loading.viewLoading();
      this.resetPassService.setReset(this.ResetForm.value).subscribe(
        data => {
          this.loading.closeLoading();
          this.successMessage = "Se ha enviado una contraseña temporal a su correo";
          setTimeout(() => {
            this.successMessage = null;
            this.router.navigate(['reset-pass-response']);
          }, 5000);
        },
        err => {
          if (err.error.message) {
            this.loading.closeLoading();
            this.errorMessage = "No se ha encontrado ningún usuario con ese correo";
          }
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }

}
