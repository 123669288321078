<section>
    <div class="info-perso">
        <form [formGroup]="formPerson" (ngSubmit)="saveData( form )" #form="ngForm">
            <mat-form-field class="example-full-width">
                <mat-label>Nombre Persona</mat-label>
                <input matInput [errorStateMatcher]="matcher" formControlName="name" [ngClass]="{ 'is-invalid': formP.name.errors }">
                <mat-error *ngIf="!!formP['name'].errors">
                    <mat-error *ngIf="!!formP['name'].errors.required">Este campo es requerido</mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input matInput [errorStateMatcher]="matcher" formControlName="email" [ngClass]="{ 'is-invalid': formP.email.errors }">
                <mat-error *ngIf="!!formP['email'].errors">
                    <mat-error *ngIf="!!formP['email'].errors.required">Este campo es requerido</mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Telefono</mat-label>
                <input matInput [errorStateMatcher]="matcher" formControlName="tel" [ngClass]="{ 'is-invalid': formP.tel.errors }">
                <mat-error *ngIf="!!formP['tel'].errors">
                    <mat-error *ngIf="!!formP['tel'].errors.required">Este campo es requerido</mat-error>
                </mat-error>
            </mat-form-field>
            <div class="button">
                <button mat-raised-button color="primary">Guardar</button>
            </div>
        </form>
        <div class="icon-button" (click)="changeData()">
            <mat-icon>edit</mat-icon>
        </div>
    </div>
</section>