<!-- <mat-card>{{name}}</mat-card> -->

<div class="cards" class="{{classStatus}}">
    <mat-card class="item-card" (click)="isActive==true && clickGuide()">
        <img mat-card-image src="{{img}}" alt="img">
        <mat-card-content>
            <p>
                {{name}}
            </p>
            <div class="status">
                {{textStatus}}
            </div>
        </mat-card-content>
    </mat-card>
</div>