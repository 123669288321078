<section class="login">
    
    <form [formGroup]="ResetForm" (ngSubmit)="RequestResetPass(ResetForm)">
        <a class="cerrar" [routerLink]="['']">X</a>
        <img src="assets/img/logo-color.png" width="150"/>
        <h3>Restablecer contraseña</h3>
        <div id="errorMsg" class="error-msg" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
        </div>
        <div id="successMsg" class="success-msg" *ngIf="successMessage">
            <span>{{successMessage}}</span>
        </div>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput [errorStateMatcher]="matcher" type="text" id="email" formControlName="email">
            <mat-error *ngIf="!!formL['email'].errors">
                <mat-error *ngIf="!!formL['email'].errors.required">Este campo es requerido</mat-error>
            </mat-error>
        </mat-form-field>
        
        <div class="button">
            <button mat-raised-button color="primary">Aceptar</button>
        </div>
    </form>
</section>